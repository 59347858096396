<template>
  <div class="fenxi-cat-tree">
    <div class="tree-area"
         v-if="treeDomId !== 'filterCatTreeId' || !$store.state.user.filterParams.comment_url_id"
         v-show="showTree">
      <div style="display: flex; line-height: 40px"
           :style="inputStyle">
        <el-input size="mini"
                  :style="{'max-width': isInner ? '280px': '460px'}"
                  v-model="filterText"
                  @change="searchChange"></el-input>
        <el-button icon="el-icon-search"
                   type="primary"
                   size="mini"
                   style="color: @fenxiPrimaryColor; font-size: 16px;width: 30px;"
                   @click="searchChange"></el-button>
        <a class="bi-link"
           href="https://bi.mktindex.com/p/taobaokeyword"
           target="_blank"
           v-if="isInner">{{$t('search.search_cat_keyword')}}</a>
      </div>
      <div v-if="$store.state.user.loginInfo.cat_list !== 'all'"
           style="height: 30px">
        <el-checkbox type="primary"
                     v-model="isAllSelect"
                     @change="allSelectChange">{{$t('search.select_all')}}</el-checkbox>
      </div>
      <ul :id="treeDomId"
          ref="catSelectTree"
          v-show="!isSearchTree"
          class="ztree"
          :style="style"
          style="overflow-y: auto"
          @select="zTreeOnClick"></ul>
      <ul :id="treeDomSearchId"
          class="ztree"
          v-if="isSearchTree"
          :style="style"
          style="overflow-y: auto"
          @select="zTreeOnClick"></ul>
    </div>
    <div class="empty-area"
         v-show="!showTree">
      <span>{{$t('search.no_category')}}</span>
    </div>
    <div v-if="$store.state.user.filterParams.comment_url_id && treeDomId == 'filterCatTreeId'">
      {{$t('search.dont_support_cat_select')}}
    </div>
  </div>
</template>


<script>
import '@/libs/js/jquery.ztree.core'
import '@/libs/js/jquery.ztree.excheck.js'
import '@/libs/js/jquery.ztree.exedit.js'
import '@/libs/css/treeSelectStyle.css'

import { DateTime } from 'luxon'
let catTreeHover = false
// 各平台类目数据地址
// 加平台需修改
const CAT_URL = {
  TB_CAT_URL:
    'https://staticjs.mktindex.com/prod/bigcats_taobao.js', // 天猫&淘宝
  JD_CAT_URL:
    'https://comment.mktindex.com/static/lingting/json/fenxibigcats_jd.js', // 京东
  CUSTOM_CAT_URL:
    'https://chance.mktindex.com/static/chance/json/bigcats_fenxi_custom.js', // 定制
  CUSTOM_CAT_URL_2:
    'https://chance.mktindex.com/static/chance/json/bigcats_mojing_custom.js', // 定制
  CUSTOM_CAT_URL_3:
    'https://chance.mktindex.com/static/chance/json/bigcats_makeup_custom.js', // 定制
  CUSTOM_CAT_URL_4:
    'https://chance.mktindex.com/static/chance/json/bigcats_water_custom.js', // 定制
  DOUYIN_CATS_URL:
    'https://chance.mktindex.com/static/chance/json/bigcats_douyin.js', // 抖音
  OVERSEA_AMAZON_US_CATS_URL:
    'https://chance.mktindex.com/static/chance/json/oversea_amazon_us.js', // 海外亚马逊美国
  OVERSEA_AMAZON_DE_CATS_URL:
    'https://chance.mktindex.com/static/chance/json/oversea_amazon_de.js', // 海外亚马逊德国
  OVERSEA_AMAZON_ES_CATS_URL:
    'https://chance.mktindex.com/static/chance/json/oversea_amazon_es.js', // 海外亚马逊西班牙
  OVERSEA_AMAZON_FR_CATS_URL:
    'https://chance.mktindex.com/static/chance/json/oversea_amazon_fr.js', // 海外亚马逊法国
  OVERSEA_AMAZON_IT_CATS_URL:
    'https://chance.mktindex.com/static/chance/json/oversea_amazon_it.js', // 海外亚马逊意大利
  OVERSEA_AMAZON_UK_CATS_URL:
    'https://chance.mktindex.com/static/chance/json/oversea_amazon_uk.js', // 海外亚马逊英国
  OVERSEA_AMAZON_JP_CATS_URL:
    'https://chance.mktindex.com/static/chance/json/oversea_amazon_jp.js', // 海外亚马逊日本
  OVERSEA_AMAZON_AE_CATS_URL:
    'https://chance.mktindex.com/static/chance/json/oversea_amazon_ae.js', // 海外亚马逊阿拉伯联合酋长国
  OVERSEA_AMAZON_SA_CATS_URL:
    'https://chance.mktindex.com/static/chance/json/oversea_amazon_sa.js', // 海外亚马逊沙特阿拉伯
  OVERSEA_AMAZON_BR_CATS_URL:
    'https://chance.mktindex.com/static/chance/json/oversea_amazon_br.js', // 海外亚马逊巴西
  OVERSEA_AMAZON_MX_CATS_URL:
    'https://chance.mktindex.com/static/chance/json/oversea_amazon_mx.js', // 海外亚马逊墨西哥
  OVERSEA_SHOPEE_VN_CATS_URL:
    'https://chance.mktindex.com/static/chance/json/bigcats_shopee_vn.js', // 虾皮越南站
  OVERSEA_SHOPEE_TH_CATS_URL:
    'https://chance.mktindex.com/static/chance/json/bigcats_shopee_th.js', // 虾皮泰国站
  OVERSEA_SHOPEE_SG_CATS_URL:
    'https://chance.mktindex.com/static/chance/json/bigcats_shopee_sg.js', // 虾皮新加坡站
  OVERSEA_SHOPEE_MY_CATS_URL:
    'https://chance.mktindex.com/static/chance/json/bigcats_shopee_my.js', // 虾皮马来西亚站
  OVERSEA_SHOPEE_PH_CATS_URL:
    'https://chance.mktindex.com/static/chance/json/bigcats_shopee_ph.js', // 虾皮菲律宾站
  OVERSEA_SHOPEE_ID_CATS_URL:
    'https://chance.mktindex.com/static/chance/json/bigcats_shopee_id.js', // 虾皮印尼站
  OVERSEA_SHOPEE_TW_CATS_URL:
    'https://chance.mktindex.com/static/chance/json/bigcats_shopee_tw.js', // 虾皮台湾站
  OVERSEA_LAZADA_VN_CATS_URL:
    'https://chance.mktindex.com/static/chance/json/bigcats_lazada_vn.js', // 来赞达越南站
  OVERSEA_LAZADA_TH_CATS_URL:
    'https://chance.mktindex.com/static/chance/json/bigcats_lazada_th.js', // 来赞达泰国站
  OVERSEA_LAZADA_SG_CATS_URL:
    'https://chance.mktindex.com/static/chance/json/bigcats_lazada_sg.js', // 来赞达新加坡站
  OVERSEA_LAZADA_MY_CATS_URL:
    'https://chance.mktindex.com/static/chance/json/bigcats_lazada_my.js', // 来赞达马来西亚站
  OVERSEA_LAZADA_PH_CATS_URL:
    'https://chance.mktindex.com/static/chance/json/bigcats_lazada_ph.js', // 来赞达菲律宾站
  OVERSEA_LAZADA_ID_CATS_URL:
    'https://chance.mktindex.com/static/chance/json/bigcats_lazada_id.js', // 来赞达印尼站
  OVERSEA_TIKTOK_GB_CATS_URL:
    'https://chancetest.mktindex.com/static/chance/json/oversea_tiktok_gb.js', // tiktok英国站
  OVERSEA_TIKTOK_ID_CATS_URL:
    'https://chancetest.mktindex.com/static/chance/json/oversea_tiktok_id.js', // tiktok印尼站
  OVERSEA_TIKTOK_MY_CATS_URL:
    'https://chancetest.mktindex.com/static/chance/json/oversea_tiktok_my.js', // tiktok马来西亚站
  OVERSEA_TIKTOK_PH_CATS_URL:
    'https://chancetest.mktindex.com/static/chance/json/oversea_tiktok_ph.js', // tiktok菲律宾站
  OVERSEA_TIKTOK_SG_CATS_URL:
    'https://chancetest.mktindex.com/static/chance/json/oversea_tiktok_sg.js', // tiktok新加坡站
  OVERSEA_TIKTOK_TH_CATS_URL:
    'https://chancetest.mktindex.com/static/chance/json/oversea_tiktok_th.js', // tiktok泰国站
  OVERSEA_TIKTOK_US_CATS_URL:
    'https://chancetest.mktindex.com/static/chance/json/oversea_tiktok_us.js', // tiktok美国站
  OVERSEA_TIKTOK_VN_CATS_URL:
    'https://chancetest.mktindex.com/static/chance/json/oversea_tiktok_vn.js', // tiktok英国站
  OVERSEA_OZON_RU_CATS_URL:
    'https://chancetest.mktindex.com/static/chance/json/bigcats_ozon.js', // OZON
  OVERSEA_HKTVMALL_HK_CATS_URL:
    'https://chancetest.mktindex.com/static/chance/json/oversea_hktvmall_hk.js', // hktvmall
}

const TB_PLAT = 'taobao'
const JD_PLAT = 'jd'
const CUSTOM_PLAT = 'custom'
const CUSTOM_PLAT_2 = 'custom_2'
const CUSTOM_PLAT_3 = 'custom_3'
const CUSTOM_PLAT_4 = 'custom_4'
const DOUYIN_PLAT = 'douyin'
const AMAZON_US_PLAT = 'amazon_us'
const SHOPEE_VN_PLAT = 'shopee_vn'
const SHOPEE_TH_PLAT = 'shopee_th'
const SHOPEE_SG_PLAT = 'shopee_sg'
const SHOPEE_MY_PLAT = 'shopee_my'
const SHOPEE_PH_PLAT = 'shopee_ph'
const SHOPEE_ID_PLAT = 'shopee_id'
const SHOPEE_TW_PLAT = 'shopee_tw'
const LAZADA_VN_PLAT = 'lazada_vn'
const LAZADA_TH_PLAT = 'lazada_th'
const LAZADA_SG_PLAT = 'lazada_sg'
const LAZADA_MY_PLAT = 'lazada_my'
const LAZADA_PH_PLAT = 'lazada_ph'
const LAZADA_ID_PLAT = 'lazada_id'
export default {
  name: 'CatTree',
  model: {
    prop: 'treeSelectValue',
    event: 'change',
  },
  props: {
    // 选择的值
    treeSelectValue: {
      type: Array,
      default() {
        return []
      },
    },
    treeDomId: {
      type: String,
      default: 'catTreeDom',
    },
    treeDomSearchId: {
      type: String,
      default: 'catSearchTreeDom',
    },
    width: {
      type: String | Number,
      default: '450',
    },
    height: {
      type: String | Number,
      default: '450',
    },
    plat: {
      type: String,
      default: '',
    },
    showCats: {
      type: String | Number,
      default: 3,
    },
    defaultCats: {
      type: Array,
      default: () => [],
    },
    allDisabled: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.debounceCheckCats = _.debounce(this.checkDefaultCats, 500)
    this.computedTimeRangeDebounce = _.debounce(this.computedTimeRange, 500)
    this.reloadTreeDebounce = _.debounce(this.reloadTree, 200)
  },

  data() {
    this.catsData = {}
    this.catTreeObj = {}
    this.searchCatTreeObj = {}
    this.catNodes = []
    return {
      isOversea: this.$store.state.user.isOversea,
      treeSelectedData: [],
      showTree: true,
      isSearchTree: false,
      filterText: '',
      inputStyle: 'min-width:' + this.width + 'px;',
      setting: {
        treeId: 'id',
        data: {
          key: {
            name: 'label',
          },
          simpleData: {
            enable: true,
            idKey: 'id',
            pIdKey: 'pId',
            rootPId: 0,
          },
        },
        view: {
          showIcon: false,
          //   showLine: false,
          fontCss: this.zTreefontCss,
          nodeClasses: this.zTreeNodeClasses,
          addHoverDom: function (treeId, treeNode) {
            let stack = new Error().stack
            if ($(`.cattree-tooltip-${treeId}`).length > 0) return
            if (stack.indexOf('onHoverOverNode') == -1) return
            var aObj = $('#' + treeNode.tId + '_a')
            aObj.css({
              position: 'relative',
            })
            var editStr = `<div role="tooltip"
             id="cattree-tooltip-${treeNode.id}"
             aria-hidden="true"
             class="el-tooltip__popper is-dark cattree-tooltip-${treeId}"
             x-placement="right"
             style="position:absolute;left:110%;top:-50%;white-space: nowrap;">
             ${treeNode.cat_tip}
             <div x-arrow="" class="tip_popper__arrow" style="top: 11px;left: -6px;"></div>
             </div>`
            if (treeNode.cat_tip) {
              aObj.append(editStr)
              aObj.one('mouseleave', function () {
                $(`.cattree-tooltip-${treeId}`).remove()
              })
            }
          },
          removeHoverDom: function (treeId, treeNode) {
            $(`.cattree-tooltip-${treeId}`).remove()
          },
          nameIsHTML: true,
        },
        check: {
          enable: true,
          chkStyle: 'checkbox',
          // radioType: 'all',
          // autoCheckTrigger: false,
          // chkDisabledInherit: false,
          // nocheckInherit: false,
          chkboxType: { Y: 'ps', N: 'ps' },
        },
        callback: {
          onClick: this.zTreeOnClick,
          onCheck: this.zTreeOnCheck,
          beforeClick: this.zTreeBeforeClick,
          beforeCheck: this.treeBeforeCheck,
        },
      },
      searchSetting: {
        treeId: 'id',
        async: {
          enable: false,
          url: '',
          autoParam: ['id'],
        },
        data: {
          key: {
            name: 'label',
          },
          simpleData: {
            enable: true,
            idKey: 'id',
            pIdKey: 'pId',
            rootPId: 0,
          },
        },
        view: {
          showIcon: false,
          //   showLine: false,
          fontCss: this.zTreeSearchfontCss,
          nodeClasses: this.zTreeSearchNodeClasses,
          addHoverDom: function (treeId, treeNode) {
            var aObj = $('#' + treeNode.tId + '_a')
            let stack = new Error().stack
            if (stack.indexOf('onHoverOverNode') == -1) return
            if ($(`.cattree-tooltip-${treeId}`).length > 0) return
            aObj.css({
              position: 'relative',
            })
            var editStr = `<div role="tooltip"
             id="cattree-tooltip-${treeNode.id}"
             aria-hidden="true"
             class="el-tooltip__popper is-dark cattree-tooltip-${treeId}"
             x-placement="right"
             style="position:absolute;left:110%;top:-50%;white-space: nowrap;">
             ${treeNode.cat_tip}
             <div x-arrow="" class="tip_popper__arrow" style="top: 11px;"></div>
             </div>`
            if (treeNode.cat_tip) {
              aObj.append(editStr)
              aObj.one('mouseleave', function () {
                $(`.cattree-tooltip-${treeId}`).remove()
              })
            }
          },
          removeHoverDom: function (treeId, treeNode) {
            $(`.cattree-tooltip-${treeId}`).remove()
          },
        },
        check: {
          enable: true,
          chkStyle: 'checkbox',
          radioType: 'all',
          autoCheckTrigger: false,
          chkDisabledInherit: false,
          nocheckInherit: false,
          chkboxType: { Y: 'ps', N: 'ps' },
        },
        callback: {
          onClick: this.zTreeSearchOnClick,
          onCheck: this.treeSearchOnCheck,
          beforeCheck: this.treeSearchBeforeCheck,
        },
      },
      options: {
        searchShowParent: true,
        searchPlaceholder: '按enter检索',
        searchKeys: null,
      },
      firstLoad: true,
      isAllSelect: false,
      oldIgnoreIds: [],
    }
  },
  computed: {
    languageMode() {
      return this.$store.state.user.languageMode
    },
    fenxiLanguageMode() {
      return this.$store.state.user.fenxiLanguageMode
    },
    style() {
      return (
        'min-width:' +
        this.width +
        'px;' +
        'height:' +
        this.height +
        'px;' +
        'max-width: 500px;'
      )
    },
    isInner() {
      if (this.$store.state.user.loginInfo) {
        return this.$store.state.user.loginInfo.username.endsWith(
          '@moojing.com'
        )
      } else {
        return false
      }
    },
  },
  watch: {
    languageMode(newVal) {
      this.firstLoad = true
      this.reloadTreeDebounce()
    },
    fenxiLanguageMode() {
      this.firstLoad = true
      this.reloadTreeDebounce()
    },
    defaultCats(newVal) {
      if (newVal.length > 0) {
        this.debounceCheckCats()
      }
    },
    showCats() {
      this.isAllSelect = false
      this.reloadTreeDebounce()
    },
    plat() {
      this.isAllSelect = false
      this.reloadTreeDebounce()
    },
  },
  methods: {
    allSelectChange(val) {
      this.filterText = ''
      this.searchChange(this.filterText)
      if (val) {
        const nodes = this.catTreeObj.getNodesByFilter((node) => {
          return !node.custom && !node.disabled && node.level == 0
        })
        // 有超过三十个权限一级类目, 默认选中前三十个类目
        if (nodes.length > 30) {
          for (let i = 0; i < 30; i++) {
            const node = nodes[i]
            this.catTreeObj.checkNode(node, true, true)
          }
        } else {
          const nodes = this.catTreeObj.getNodesByFilter((node) => {
            // 非自定义类目
            if (node.custom) {
              return false
            }
            // 选择最顶层的父级类目
            if (node.disabled) {
              return false
            } else {
              const parentNode = node.getParentNode()
              if (!parentNode) {
                // 一级类目
                return true
              } else {
                if (parentNode.disabled) {
                  return true
                } else {
                  return false
                }
              }
            }
          })
          nodes.forEach((item) => {
            this.catTreeObj.checkNode(item, true, true)
          })
          // this.catTreeObj.checkAllNodes(true)
        }
      } else {
        // 取消全选
        this.catTreeObj.checkAllNodes(false)
      }
      if (
        this.$store.state.user.customCat &&
        this.$store.state.user.customCat.length > 0
      ) {
        this.computedTimeRangeDebounce()
      }
      this.treeSelectedData = []
      let ignoreIds = []
      this.catTreeObj.getCheckedNodes().forEach((item) => {
        const { path, cid_path } = this.computeNodePath(item)
        const catInfo = {
          _id: item._id ? item._id : item.id,
          custom: item.custom,
          name: item.label,
          is_parent: item.is_parent,
          sub_name: item.sub_name,
          id: item.id,
          fix_id: item.id,
          top_id: item.id,
          path,
          cid_path,
          // fix_platform: 'all',
          // checked: false,
          // open: false,
          // pId: '0',
          // path: ["女装/女士精品"],
          // sub_name: "",
          // support_plat_name: '淘宝',
          // top_id: '16'
        }
        // 该节点被全选且父节点未被选中,则子节点id不传入
        if (item.check_Child_State == 2 && ignoreIds.indexOf(item.id) == -1) {
          this.treeSelectedData.push(catInfo)
          ignoreIds.push(...this.getChdNodesIds(item))
        } // 父节点不是全选,子节点没有children
        else if (
          item.check_Child_State == -1 &&
          ignoreIds.indexOf(item.id) == -1
        ) {
          this.treeSelectedData.push(catInfo)
        }
        // return catInfo
      })
      // this.expandCheckedNodes()
      this.$emit('change', this.treeSelectedData)
    },
    reloadTree() {
      this.treeSelectedData = []
      this.$emit('change', this.treeSelectedData)
      this.loadCats()
      this.searchChange(this.filterText)
      if (this.defaultCats.length > 0 && this.firstLoad) {
        this.debounceCheckCats()
        this.firstLoad = false
      }
    },
    getChdNodesIds(nodes) {
      const ids = []
      if (nodes.children && nodes.children.length > 0) {
        nodes.children.forEach((item) => {
          ids.push(item.id)
          if (item.children && item.children.length > 0) {
            ids.push(...this.getChdNodesIds(item))
          }
        })
      }
      return ids
    },
    zTreefontCss(treeId, treeNode) {
      if (treeNode.disabled) {
        return { color: '#7D8A9B', 'font-family': 'Nunito' }
      } else {
        return { 'font-family': 'Nunito !important' }
      }

      // if (treeNode.isParent) {
      //   return { color: '#337ab7' }
      // } else {
      //   return { color: '#337ab7' }
      // }
    },
    zTreeSearchfontCss(treeId, treeNode) {
      // if (treeNode.isParent) {
      //   // return { color: '#337ab7' }
      //   return { color: '#337ab7' }
      // } else {
      //   // return { color: '#337ab7' }
      //   return { color: '#337ab7'}
      // }
      if (treeNode.isSearchNode) {
        return { color: '#dc1d35' }
      }
    },
    // 加平台需修改
    async loadCats() {
      /* 类目显示规则
        1: 'taobao',
        2: 'jd',
        3: 'taobao+custom',
        4: 'jd+custom'
        5: 'taobao+jd',
        6: 'empty',
        7: 'amazon'
        8: 'douyin'
      */

      this.catsData = {}
      this.showTree = true
      const loginInfo = this.$store.state.user.loginInfo
      let all_cat_list
      let taobao_cat_list
      let tmall_cat_list
      let jd_cat_list
      let amazon_cat_list
      let douyin_cat_list
      let shopee_cat_list
      let lazada_cat_list
      let tiktok_cat_list
      let ozon_cat_list
      let hktvmall_cat_list
      let self = this
      let showAllCat = loginInfo.show_all_cats
      // 试用用户的判断符
      let showCustom = loginInfo.is_add_custom
      this.showCatsList = {}
      if (loginInfo !== 'all') {
        all_cat_list = loginInfo.cat_list.all
        taobao_cat_list = loginInfo.cat_list.cshop
        tmall_cat_list = loginInfo.cat_list.tmall
        jd_cat_list = loginInfo.cat_list.jd
        amazon_cat_list = getOverseaLoginCatList('amazon')
        douyin_cat_list = loginInfo.cat_list.douyin
        shopee_cat_list = getOverseaLoginCatList('shopee')
        lazada_cat_list = getOverseaLoginCatList('lazada')
        tiktok_cat_list = getOverseaLoginCatList('tiktok')
        ozon_cat_list = getOverseaLoginCatList('ozon')
        hktvmall_cat_list = getOverseaLoginCatList('hktvmall')
      }
      /**
       * 与后端约定,user接口返回的
       * show_custom_101
       * show_custom_102
       * 字段控制自定义类目的显隐
       */
      function getOverseaLoginCatList(platType) {
        let filterParams =
          JSON.parse(sessionStorage.getItem('filterParams')) || {}
        let plat = self.plat
        if (!plat) {
          if (filterParams.plat) {
            plat = filterParams.plat
          } else {
            plat = self.$store.state.user.isOversea ? 'amazon_us' : 'taobao'
          }
        }
        if (platType == 'amazon') {
          plat = plat.indexOf('amazon') > -1 ? plat : 'amazon_us'
        } else if (platType == 'shopee') {
          plat = plat.indexOf('shopee') > -1 ? plat : 'shopee'
        } else if (platType == 'lazada') {
          plat = plat.indexOf('lazada') > -1 ? plat : 'lazada'
        } else if (platType == 'tiktok') {
          plat = plat.indexOf('tiktok') > -1 ? plat : 'tiktok'
        } else if (platType == 'ozon') {
          plat = plat.indexOf('ozon') > -1 ? plat : 'ozon_ru'
        } else if (platType == 'hktvmall') {
          plat = plat.indexOf('hktvmall') > -1 ? plat : 'hktvmall_hk'
        }
        return loginInfo.cat_list[plat] || {}
      }
      if (this.showCats == 1) {
        const tbData = await this.getCatData(TB_PLAT)
        let plat = self.plat
        let filterParams =
          JSON.parse(sessionStorage.getItem('filterParams')) || {}
        if (!plat) {
          if (filterParams.plat) {
            plat = filterParams.plat
          } else {
            plat = self.$store.state.user.isOversea ? 'amazon_us' : 'taobao'
          }
        }

        let customData = await this.getCatData(CUSTOM_PLAT)
        let customData_4 = await this.getCatData(CUSTOM_PLAT_4)
        if (plat.indexOf('douyin') > -1 && plat.indexOf('jd') == -1) {
          let customData_2 = await this.getCatData(CUSTOM_PLAT_2)
          let customData_3 = await this.getCatData(CUSTOM_PLAT_3)
          if (!showCustom) {
            customData =
              customData_2 =
              customData_3 =
                {
                  taobao: {
                    bigCategory: [],
                    childrenList: {},
                    choice_cat: '',
                  },
                  'taobao+jd': {
                    bigCategory: [],
                    childrenList: {},
                    choice_cat: '',
                  },
                }
            customData_4 = {
              taobao: {
                bigCategory: [],
                childrenList: {},
                choice_cat: '',
              },
              jd: {
                bigCategory: [],
                childrenList: {},
                choice_cat: '',
              },
              douyin: {
                bigCategory: [],
                childrenList: {},
                choice_cat: '',
              },
            }
          }
          this.catsData.result = {}
          this.catsData.result.bigCategory = customData[
            'taobao+jd'
          ].bigCategory.concat(
            customData_2.taobao.bigCategory,
            customData_3.taobao.bigCategory,
            customData_4.taobao.bigCategory,
            tbData.result.bigCategory
          )
          this.catsData.result.childrenList = Object.assign(
            {},
            tbData.result.childrenList,
            customData['taobao+jd'].childrenList,
            customData_2.taobao.childrenList,
            customData_3.taobao.childrenList,
            customData_4['taobao'].childrenList
          )
        } else if (
          plat.indexOf('douyin') > -1 &&
          plat.indexOf('jd') > -1 &&
          plat.split(',').length > 2
        ) {
          if (!showCustom) {
            customData = {
              taobao: {
                bigCategory: [],
                childrenList: {},
                choice_cat: '',
              },
              'taobao+jd': {
                bigCategory: [],
                childrenList: {},
                choice_cat: '',
              },
            }
            customData_4 = {
              taobao: {
                bigCategory: [],
                childrenList: {},
                choice_cat: '',
              },
              jd: {
                bigCategory: [],
                childrenList: {},
                choice_cat: '',
              },
              douyin: {
                bigCategory: [],
                childrenList: {},
                choice_cat: '',
              },
            }
          }
          this.catsData.result = {}
          this.catsData.result.bigCategory = customData[
            'taobao+jd'
          ].bigCategory.concat(
            customData_4.taobao.bigCategory,
            tbData.result.bigCategory
          )
          this.catsData.result.childrenList = Object.assign(
            {},
            tbData.result.childrenList,
            customData['taobao+jd'].childrenList,
            customData_4['taobao'].childrenList
          )
        } else {
          // this.catsData = tbData
          this.catsData.result = {}
          this.catsData.result.bigCategory = customData[
            'taobao+jd'
          ].bigCategory.concat(tbData.result.bigCategory)
          this.catsData.result.childrenList = Object.assign(
            {},
            tbData.result.childrenList,
            customData['taobao+jd'].childrenList
          )
        }
        // 淘系去掉'全部'类目
        const bigArr = this.catsData.result.bigCategory
        const index1 = bigArr.indexOf('0')
        bigArr.splice(index1, 1)
        delete this.catsData.result.childrenList['0']
        let cat_list
        if (loginInfo.cat_list !== 'all') {
          cat_list = {}
          for (const key in all_cat_list) {
            if (Object.hasOwnProperty.call(all_cat_list, key)) {
              const element = all_cat_list[key]
              cat_list[key] = element.concat(
                taobao_cat_list[key],
                tmall_cat_list[key]
              )
            }
          }
          let filterParams =
            JSON.parse(sessionStorage.getItem('filterParams')) || {}
          if (plat.indexOf('douyin') > -1) {
            cat_list = this.computeDouyinCatList(cat_list)
          }
          this.showCatsList[this.showCats] = cat_list
        } else {
          cat_list = 'all'
        }
        this.startWorker(bigArr, cat_list).then((res) => {
          this.zNodes = res
          this.initCatTree()
        })
      } else if (this.showCats == 2) {
        this.catsData = await this.getCatData(JD_PLAT)
        this.showCatsList[this.showCats] = jd_cat_list
        let cat_list = {}
        if (jd_cat_list) {
          cat_list = jd_cat_list
        } else {
          cat_list = 'all'
        }
        this.startWorker(this.catsData.result.bigCategory, cat_list).then(
          (res) => {
            this.zNodes = res
            this.initCatTree()
          }
        )
      } else if (this.showCats == 3) {
        // 'taobao+tmall' & 'taobao' & 'tamll'
        const tbData = await this.getCatData(TB_PLAT)
        let customData = await this.getCatData(CUSTOM_PLAT)
        let customData_2 = await this.getCatData(CUSTOM_PLAT_2)
        let customData_3 = await this.getCatData(CUSTOM_PLAT_3)
        let customData_4 = await this.getCatData(CUSTOM_PLAT_4)
        console.log(customData_4)
        if (!showCustom) {
          customData =
            customData_2 =
            customData_3 =
              {
                taobao: {
                  bigCategory: [],
                  childrenList: {},
                  choice_cat: '',
                },
                'taobao+jd': {
                  bigCategory: [],
                  childrenList: {},
                  choice_cat: '',
                },
              }
          customData_4 = {
            taobao: {
              bigCategory: [],
              childrenList: {},
              choice_cat: '',
            },
            jd: {
              bigCategory: [],
              childrenList: {},
              choice_cat: '',
            },
            douyin: {
              bigCategory: [],
              childrenList: {},
              choice_cat: '',
            },
          }
        }
        // 淘系去掉'全部'类目
        const bigArr = tbData.result.bigCategory
        const index1 = bigArr.indexOf('0')
        bigArr.splice(index1, 1)
        delete tbData.result.childrenList['0']
        let cat_list
        if (loginInfo.cat_list !== 'all') {
          cat_list = {}
          for (const key in all_cat_list) {
            if (Object.hasOwnProperty.call(all_cat_list, key)) {
              const element = all_cat_list[key]
              cat_list[key] = element.concat(
                taobao_cat_list[key],
                tmall_cat_list[key]
              )
            }
          }
          this.showCatsList[this.showCats] = cat_list
        } else {
          cat_list = 'all'
        }
        this.catsData.result = {}
        this.catsData.result.bigCategory = customData.taobao.bigCategory.concat(
          customData['taobao+jd'].bigCategory,
          customData_2.taobao.bigCategory,
          customData_3.taobao.bigCategory,
          customData_4.taobao.bigCategory,
          bigArr
        )
        this.catsData.result.childrenList = Object.assign(
          {},
          tbData.result.childrenList,
          customData.taobao.childrenList,
          customData['taobao+jd'].childrenList,
          customData_2.taobao.childrenList,
          customData_3.taobao.childrenList,
          customData_4.taobao.childrenList
        )
        this.startWorker(this.catsData.result.bigCategory, cat_list).then(
          (res) => {
            this.zNodes = res
            this.initCatTree()
          }
        )
      } else if (this.showCats == 4) {
        // 'jd'
        let customData = await this.getCatData(CUSTOM_PLAT)
        let customData_4 = await this.getCatData(CUSTOM_PLAT_4)
        const jdData = await this.getCatData(JD_PLAT)
        if (!showCustom) {
          customData = {
            taobao: {
              bigCategory: [],
              childrenList: {},
              choice_cat: '',
            },
            'taobao+jd': {
              bigCategory: [],
              childrenList: {},
              choice_cat: '',
            },
          }
          customData_4 = {
            taobao: {
              bigCategory: [],
              childrenList: {},
              choice_cat: '',
            },
            jd: {
              bigCategory: [],
              childrenList: {},
              choice_cat: '',
            },
            douyin: {
              bigCategory: [],
              childrenList: {},
              choice_cat: '',
            },
          }
        }
        this.catsData.result = {}
        this.catsData.result.bigCategory = customData[
          'taobao+jd'
        ].bigCategory.concat(
          customData_4.jd.bigCategory,
          jdData.result.bigCategory
        )
        this.catsData.result.childrenList = Object.assign(
          {},
          jdData.result.childrenList,
          customData['taobao+jd'].childrenList,
          customData_4['jd'].childrenList
        )
        let cat_list
        if (loginInfo.cat_list !== 'all') {
          cat_list = {}
          for (const key in all_cat_list) {
            if (Object.hasOwnProperty.call(all_cat_list, key)) {
              let element = all_cat_list[key]
              element = element.filter(
                (item) => item.cat_id.indexOf('custom') > -1
              )
              cat_list[key] = element.concat(jd_cat_list[key])
            }
          }
          this.showCatsList[this.showCats] = cat_list
        } else {
          cat_list = 'all'
        }
        this.startWorker(this.catsData.result.bigCategory, cat_list).then(
          (res) => {
            this.zNodes = res
            this.initCatTree()
          }
        )
      } else if (this.showCats == 5) {
        let customData = await this.getCatData(CUSTOM_PLAT)
        this.catsData.result = {}
        if (!showCustom) {
          customData = {
            taobao: {
              bigCategory: [],
              childrenList: {},
              choice_cat: '',
            },
            'taobao+jd': {
              bigCategory: [],
              childrenList: {},
              choice_cat: '',
            },
          }
        }
        let cat_list
        if (loginInfo.cat_list !== 'all') {
          cat_list = {}
          for (const key in all_cat_list) {
            if (Object.hasOwnProperty.call(all_cat_list, key)) {
              let element = all_cat_list[key]
              element = element.filter(
                (item) => item.cat_id.indexOf('custom') > -1
              )
              cat_list[key] = element.concat(jd_cat_list[key])
            }
          }
          this.showCatsList[this.showCats] = cat_list
        } else {
          cat_list = 'all'
        }
        this.catsData.result.bigCategory = customData['taobao+jd'].bigCategory
        this.catsData.result.childrenList = customData['taobao+jd'].childrenList
        this.startWorker(this.catsData.result.bigCategory, cat_list).then(
          (res) => {
            this.zNodes = res
            this.initCatTree()
          }
        )
      } else if (this.showCats == 6) {
        this.showTree = false
      } else if (this.showCats == 11) {
        let catsDataArr = await this.getCatData(TB_PLAT)
        // 淘系去掉'全部'类目
        const bigArr = catsDataArr.result.bigCategory
        const index1 = bigArr.indexOf('0')
        bigArr.splice(index1, 1)
        delete catsDataArr.result.childrenList['0']

        let customData = await this.getCatData(CUSTOM_PLAT)
        let customData_4 = await this.getCatData(CUSTOM_PLAT_4)
        this.catsData.result = {}
        if (!showCustom) {
          customData = {
            taobao: {
              bigCategory: [],
              childrenList: {},
              choice_cat: '',
            },
            'taobao+jd': {
              bigCategory: [],
              childrenList: {},
              choice_cat: '',
            },
          }
          customData_4 = {
            taobao: {
              bigCategory: [],
              childrenList: {},
              choice_cat: '',
            },
            jd: {
              bigCategory: [],
              childrenList: {},
              choice_cat: '',
            },
            douyin: {
              bigCategory: [],
              childrenList: {},
              choice_cat: '',
            },
          }
        }
        let cat_list
        if (loginInfo.cat_list !== 'all') {
          cat_list = {}
          let hasJdMultiPlat = loginInfo.multi_plat && loginInfo.multi_plat.jd
          for (const key in all_cat_list) {
            if (Object.hasOwnProperty.call(all_cat_list, key)) {
              const element = all_cat_list[key]
              cat_list[key] = element.concat(
                jd_cat_list[key],
                taobao_cat_list[key],
                tmall_cat_list[key]
              )
            }
          }
          if (hasJdMultiPlat) {
            const multiJdCatList = loginInfo.multi_plat.jd
            const allTopId = []
            for (const level in cat_list) {
              const levelArr = cat_list[level]
              levelArr.forEach((item) => {
                if (allTopId.indexOf(item.cid_path[0]) == -1) {
                  allTopId.push(item.cid_path[0])
                }
              })
            }
            for (const level in multiJdCatList) {
              if (multiJdCatList.hasOwnProperty(level)) {
                const levelObj = multiJdCatList[level]
                // 如果配置的有OTC定制类目, 自动加上
                const custom_102_obj = cat_list[level].find(
                  (ele) => ele.cat_id.indexOf('custom_102') > -1
                )
                if (custom_102_obj) {
                  levelObj.push(custom_102_obj)
                }
                const filterObj = levelObj.filter((item) => {
                  return (
                    cat_list[level].some((ele) => {
                      return ele.cat_id == item.cat_id
                    }) || allTopId.indexOf(item.cid_path[0]) > -1
                  )
                })
                multiJdCatList[level] = filterObj
              }
            }
            cat_list = _.cloneDeep(multiJdCatList)
          }
          this.showCatsList[this.showCats] = cat_list
        } else {
          cat_list = 'all'
        }

        // this.catsData.result.bigCategory = customData['taobao+jd'].bigCategory
        // this.catsData.result.childrenList = customData['taobao+jd'].childrenList
        this.catsData.result.bigCategory = customData[
          'taobao+jd'
        ].bigCategory.concat(
          customData_4.taobao.bigCategory,
          catsDataArr.result.bigCategory,
          bigArr
        )
        this.catsData.result.childrenList = Object.assign(
          {},
          customData['taobao+jd'].childrenList,
          catsDataArr.result.childrenList,
          customData_4['taobao'].childrenList
        )
        this.startWorker(this.catsData.result.bigCategory, cat_list).then(
          (res) => {
            this.zNodes = res
            this.initCatTree()
          }
        )
      } else if (this.showCats == 7) {
        this.catsData = await this.getCatData(AMAZON_US_PLAT)
        const bigArr = this.catsData.result.bigCategory
        let cat_list
        if (loginInfo.cat_list !== 'all') {
          cat_list = {}
          if (amazon_cat_list == 'all') {
            cat_list = 'all'
          } else {
            for (const key in amazon_cat_list) {
              if (Object.hasOwnProperty.call(amazon_cat_list, key)) {
                const element = amazon_cat_list[key]
                cat_list[key] = element.concat([])
              }
            }
          }
          this.showCatsList[this.showCats] = cat_list
        } else {
          cat_list = 'all'
        }
        if (showAllCat) {
          cat_list = 'all'
        }
        this.startWorker(bigArr, cat_list).then((res) => {
          this.zNodes = res
          this.initCatTree()
        })
      } else if (this.showCats == 8) {
        let catsData = await this.getCatData(DOUYIN_PLAT)
        let customData = await this.getCatData(CUSTOM_PLAT)
        let customData_2 = await this.getCatData(CUSTOM_PLAT_2)
        let customData_3 = await this.getCatData(CUSTOM_PLAT_3)
        let customData_4 = await this.getCatData(CUSTOM_PLAT_4)
        this.catsData.result = {}
        if (!showCustom) {
          customData =
            customData_2 =
            customData_3 =
              {
                taobao: {
                  bigCategory: [],
                  childrenList: {},
                  choice_cat: '',
                },
                'taobao+jd': {
                  bigCategory: [],
                  childrenList: {},
                  choice_cat: '',
                },
              }
          customData_4 = {
            taobao: {
              bigCategory: [],
              childrenList: {},
              choice_cat: '',
            },
            jd: {
              bigCategory: [],
              childrenList: {},
              choice_cat: '',
            },
            douyin: {
              bigCategory: [],
              childrenList: {},
              choice_cat: '',
            },
          }
        }
        this.catsData.result.bigCategory = customData[
          'taobao+jd'
        ].bigCategory.concat(
          customData_2.taobao.bigCategory,
          customData_3.taobao.bigCategory,
          customData_4.douyin.bigCategory,
          catsData.result.bigCategory
        )
        this.catsData.result.childrenList = Object.assign(
          {},
          customData['taobao+jd'].childrenList,
          customData_2.taobao.childrenList,
          customData_3.taobao.childrenList,
          catsData.result.childrenList,
          customData_4['douyin'].childrenList
        )
        const bigArr = this.catsData.result.bigCategory
        let cat_list
        if (loginInfo.cat_list !== 'all') {
          cat_list = {}
          for (const key in all_cat_list) {
            if (Object.hasOwnProperty.call(all_cat_list, key)) {
              let element = all_cat_list[key]
              element = element.filter(
                (item) => item.cat_id.indexOf('custom') > -1
              )
              cat_list[key] = element.concat(douyin_cat_list[key])
            }
          }
          this.showCatsList[this.showCats] = cat_list
        } else {
          cat_list = 'all'
        }
        if (showAllCat) {
          cat_list = 'all'
        }
        this.startWorker(bigArr, cat_list).then((res) => {
          this.zNodes = res
          this.initCatTree()
        })
      } else if (
        typeof this.showCats == 'string' &&
        this.showCats.constructor == String
      ) {
        if (
          this.showCats.indexOf('amazon') > -1 ||
          this.showCats.indexOf('shopee') > -1 ||
          this.showCats.indexOf('lazada') > -1 ||
          this.showCats.indexOf('tiktok') > -1 ||
          this.showCats.indexOf('ozon') > -1 ||
          this.showCats.indexOf('hktvmall') > -1
        ) {
          this.catsData = await this.getCatData(this.showCats)
          const bigArr = this.catsData.result.bigCategory
          let cat_list
          if (loginInfo.cat_list !== 'all') {
            cat_list = {}
            if (this.showCats.indexOf('amazon') > -1) {
              if (amazon_cat_list == 'all') {
                cat_list = 'all'
              } else {
                for (const key in amazon_cat_list) {
                  if (Object.hasOwnProperty.call(amazon_cat_list, key)) {
                    const element = amazon_cat_list[key]
                    cat_list[key] = element.concat([])
                  }
                }
              }
            }
            if (this.showCats.indexOf('shopee') > -1) {
              if (shopee_cat_list == 'all') {
                cat_list = 'all'
              } else {
                for (const key in shopee_cat_list) {
                  if (Object.hasOwnProperty.call(shopee_cat_list, key)) {
                    const element = shopee_cat_list[key]
                    cat_list[key] = element.concat([])
                  }
                }
              }
            }
            if (this.showCats.indexOf('lazada') > -1) {
              if (lazada_cat_list == 'all') {
                cat_list = 'all'
              } else {
                for (const key in lazada_cat_list) {
                  if (Object.hasOwnProperty.call(lazada_cat_list, key)) {
                    const element = lazada_cat_list[key]
                    cat_list[key] = element.concat([])
                  }
                }
              }
            }
            if (this.showCats.indexOf('tiktok') > -1) {
              if (tiktok_cat_list == 'all') {
                cat_list = 'all'
              } else {
                for (const key in tiktok_cat_list) {
                  if (Object.hasOwnProperty.call(tiktok_cat_list, key)) {
                    const element = tiktok_cat_list[key]
                    cat_list[key] = element.concat([])
                  }
                }
              }
            }
            if (this.showCats.indexOf('ozon') > -1) {
              if (ozon_cat_list == 'all') {
                cat_list = 'all'
              } else {
                for (const key in ozon_cat_list) {
                  if (Object.hasOwnProperty.call(ozon_cat_list, key)) {
                    const element = ozon_cat_list[key]
                    cat_list[key] = element.concat([])
                  }
                }
              }
            }
            if (this.showCats.indexOf('hktvmall') > -1) {
              if (hktvmall_cat_list == 'all') {
                cat_list = 'all'
              } else {
                for (const key in hktvmall_cat_list) {
                  if (Object.hasOwnProperty.call(hktvmall_cat_list, key)) {
                    const element = hktvmall_cat_list[key]
                    cat_list[key] = element.concat([])
                  }
                }
              }
            }
            this.showCatsList[this.showCats] = cat_list
          } else {
            cat_list = 'all'
          }
          if (showAllCat) {
            cat_list = 'all'
          }
          this.startWorker(bigArr, cat_list).then((res) => {
            this.zNodes = res
            this.initCatTree()
          })
        }
      }
      // this.catsData = await this.getCatData(TB_PLAT)
      // let arrs = []
      // arrs = this.catsData.result.bigCategory;

      // // 淘系去掉'全部'类目
      // const index = arrs.indexOf("0")
      // arrs.splice(index,1)

      // this.startWorker(arrs).then(res => {
      //   this.zNodes = res
      //   this.initCatTree()
      // })
    },
    // 筛选出抖音多平台的显示类目
    computeDouyinCatList(catList) {
      let cat_list = {}
      const loginInfo = this.$store.state.user.loginInfo
      let hasDouyinMultiPlat =
        loginInfo.multi_plat && loginInfo.multi_plat.douyin
      const allTopId = []
      for (const level in catList) {
        const levelArr = catList[level]
        levelArr.forEach((item) => {
          if (allTopId.indexOf(item.cid_path[0]) == -1) {
            allTopId.push(item.cid_path[0])
          }
        })
      }
      if (this.plat.indexOf('jd') > -1) {
        // 京东 & 抖音 & 淘系权限
        if (hasDouyinMultiPlat) {
          const multiDouyinCatList = loginInfo.multi_plat.douyin
          const multiJdCatList = loginInfo.multi_plat.jd
          for (const level in multiDouyinCatList) {
            if (multiDouyinCatList.hasOwnProperty(level)) {
              const levelObj = multiDouyinCatList[level]
              // 如果配置的有OTC定制类目, 自动加上
              const custom_102_obj = catList[level].find(
                (ele) => ele.cat_id.indexOf('custom_102') > -1
              )
              if (custom_102_obj) {
                levelObj.push(custom_102_obj)
              }
              const filterObj = levelObj.filter((item) => {
                return (
                  catList[level].some((ele) => ele.cat_id == item.cat_id) ||
                  allTopId.indexOf(item.cid_path[0]) > -1
                )
              })
              multiDouyinCatList[level] = filterObj
            }
          }
          for (const level in multiDouyinCatList) {
            if (multiDouyinCatList.hasOwnProperty(level)) {
              const levelObj = multiDouyinCatList[level] // 如果配置的有OTC定制类目, 自动加上
              const custom_102_obj = catList[level].find(
                (ele) => ele.cat_id.indexOf('custom_102') > -1
              )
              if (custom_102_obj) {
                levelObj.push(custom_102_obj)
              }
              const filterObj = levelObj.filter((item) => {
                return (
                  multiJdCatList[level].some(
                    (ele) => ele.cat_id == item.cat_id
                  ) || allTopId.indexOf(item.cid_path[0]) > -1
                )
              })
              multiDouyinCatList[level] = filterObj
            }
          }
          cat_list = _.cloneDeep(multiDouyinCatList)
        }
        return cat_list
      } else {
        // 抖音 & 淘系权限
        if (hasDouyinMultiPlat) {
          const multiDouyinCatList = loginInfo.multi_plat.douyin
          for (const level in multiDouyinCatList) {
            if (multiDouyinCatList.hasOwnProperty(level)) {
              const levelObj = multiDouyinCatList[level]
              const filterObj = levelObj.filter((item) => {
                return (
                  catList[level].some((ele) => {
                    return ele.cat_id == item.cat_id
                  }) || allTopId.indexOf(item.cid_path[0]) > -1
                )
              })
              multiDouyinCatList[level] = filterObj
            }
          }
          cat_list = _.cloneDeep(multiDouyinCatList)
        }
        return cat_list
      }
    },
    initCatTree() {
      const treeObj = $.fn.zTree.init(
        $('#' + this.treeDomId),
        this.setting,
        this.zNodes
      )
      const disabledNodes = treeObj.getNodesByParam('disabled', true)
      disabledNodes.forEach((item) => {
        treeObj.setChkDisabled(item, true)
      })
      this.catTreeObj = treeObj
    },
    zTreeOnClick() {},
    zTreeOnCheck(event, treeId, treeNode) {
      this.treeSelectedData = []
      let ignoreIds = []
      this.catTreeObj.getCheckedNodes().forEach((item) => {
        const { path, cid_path } = this.computeNodePath(item)
        const catInfo = {
          _id: item._id ? item._id : item.id,
          custom: item.custom,
          name: item.label,
          is_parent: item.is_parent,
          sub_name: item.sub_name,
          id: item.id,
          fix_id: item.id,
          top_id: item.id,
          path,
          cid_path,
          // fix_platform: 'all',
          // checked: false,
          // open: false,
          // pId: '0',
          // path: ["女装/女士精品"],
          // sub_name: "",
          // support_plat_name: '淘宝',
          // top_id: '16'
        }
        // 该节点被全选且父节点未被选中,则子节点id不传入
        if (item.check_Child_State == 2 && ignoreIds.indexOf(item.id) == -1) {
          // 若oldIgnoreIds没有值，不影响之前的逻辑
          let idIndex = -2
          if (this.oldIgnoreIds.length > 0) {
            idIndex = this.oldIgnoreIds.findIndex((i) => i == item.id)
          }
          if (!(item.children.length == 1 && item.children[0].checked)) {
            this.treeSelectedData.push(catInfo)
            ignoreIds.push(...this.getChdNodesIds(item))
          } else if (
            (item.id == treeNode.id || idIndex == -1) &&
            item.children.length == 1 &&
            item.children[0].checked
          ) {
            this.treeSelectedData.push(catInfo)
            ignoreIds.push(...this.getChdNodesIds(item))
          }
        } // 父节点不是全选,子节点没有children
        else if (
          item.check_Child_State == -1 &&
          ignoreIds.indexOf(item.id) == -1
        ) {
          this.treeSelectedData.push(catInfo)
        }
        // return catInfo
      })
      this.oldIgnoreIds = ignoreIds
      if (
        this.$store.state.user.customCat &&
        this.$store.state.user.customCat.length > 0
      ) {
        this.computedTimeRangeDebounce()
      }
      this.$emit('change', this.treeSelectedData)
      this.topCheckedNodes(event, treeId, treeNode)
      this.catTreeObj.selectNode(treeNode, true, false)
    },
    /**
     * 置顶已选中的类目
     */
    topCheckedNodes(event, treeId, treeNode) {
      // console.log(treeNode)
      // let checkedParentNodes = []
      // const checkedNodes = this.catTreeObj.getCheckedNodes()
      // checkedParentNodes = checkedNodes.map((item) => item.getPath()[0])
      // checkedParentNodes.forEach((item, index) => {
      //   const nodes = this.catTreeObj.getNodes()
      //   this.catTreeObj.moveNode(nodes[index], item, 'prev')
      // })
      if (treeNode) {
        treeNode.getPath().forEach((node) => {
          this.topNode(event, treeId, node)
        })
      }
    },
    /**
     * ! 在同级节点中置顶
     * @param {Object} event
     * @param {String} treeId
     * @param {Object} treeNode
     */
    topNode(event, treeId, treeNode) {
      const catTreeObj = $.fn.zTree.getZTreeObj(treeId)
      if (!treeNode.isFirstNode) {
        // 如果本身不是置顶节点,执行置顶操作
        let currentNodes = []
        const parentNode = treeNode.getParentNode()
        if (parentNode) {
          // 非根节点置顶
          currentNodes = catTreeObj.getNodesByFilter((node) => {
            if (node.getParentNode()) {
              return node.getParentNode().id == parentNode.id
            }
          })
          catTreeObj.moveNode(currentNodes[0], treeNode, 'prev')
        } else {
          // 根节点前置
          currentNodes = catTreeObj.getNodes()
          catTreeObj.moveNode(currentNodes[0], treeNode, 'prev')
        }
      }
    },
    computedTimeRange() {
      const customCat = this.$store.state.user.customCat
      let dateTime = []
      if (this.catTreeObj.getCheckedNodes().length > 0) {
        this.catTreeObj.getCheckedNodes().forEach((item, index) => {
          const parentId = item
            .getPath()
            .find((item) => item.disabled == false).id
          let cat
          if (this.$store.state.user.isOversea) {
            cat = customCat.find(
              (item) =>
                item.cats._id == parentId && item.platform == this.showCats
            )
          } else {
            cat = customCat.findLast((item) => item.cats._id == parentId)
          }
          if (parentId.indexOf('custom_103') > -1) {
            cat = customCat.findLast((item) => item.cats.id == parentId)
          }
          if (!cat) {
            // 当前平台下是否存在 id 为 0的类目,设置当前类目的dateTime
            cat = customCat.find(
              (item) => item.cats._id == '0' && this.isSamePlat(item.platform)
            )
          }
          if (cat && cat.data_start && cat.data_end) {
            if (index == 0) {
              dateTime = [cat.data_start, cat.data_end]
            }
            const catDateTime = [cat.data_start, cat.data_end]
            if (
              DateTime.fromISO(catDateTime[0]).ts <
              DateTime.fromISO(dateTime[0]).ts
            ) {
              dateTime[0] = catDateTime[0]
            }
            if (
              DateTime.fromISO(catDateTime[1]).ts >
              DateTime.fromISO(dateTime[1]).ts
            ) {
              dateTime[1] = catDateTime[1]
            }
          }
        })
      } else {
        dateTime = []
      }
      this.$emit('on-changeDateTime', dateTime)
    },
    isSamePlat(platform) {
      const plat = this.plat
      if (this.$store.state.user.isOversea) {
        // oversea 暂时不需要调整
        return false
        // if (platform == plat) {
        //   return true
        // } else {
        //   return false
        // }
      } else {
        // 同为京东平台
        if (platform.indexOf('jd') == 0) {
          if (plat.indexOf('jd') == 0) {
            return true
          } else {
            return false
          }
        }
        // 同为抖音平台
        else if (platform.indexOf('douyin') > -1) {
          if (plat.indexOf('douyin') > -1) {
            return true
          } else {
            return false
          }
        }
        // 同为淘宝平台
        else {
          return true
        }
      }
    },
    getCatInfoById(id) {
      const node = this.catTreeObj.getNodeByParam('id', id)
      const { path, cid_path } = this.computeNodePath(node)
      const catInfo = {
        _id: node._id ? node._id : node.id,
        custom: node.custom,
        name: node.label,
        is_parent: node.is_parent,
        sub_name: node.sub_name,
        id: node.id,
        fix_id: node.id,
        top_id: node.id,
        path,
        cid_path,
        // fix_platform: 'all',
        // checked: false,
        // open: false,
        // pId: '0',
        // path: ["女装/女士精品"],
        // sub_name: "",
        // support_plat_name: '淘宝',
        // top_id: '16'
      }
      return catInfo
    },
    zTreeBeforeClick() {},
    treeBeforeCheck(treeId, treeNode) {
      // 限制用户选择少于30个类目
      if (!this.limitCat_30(treeNode)) {
        return false
      }
      const treeSelectedArr = this.treeSelectedData.map((item) => {
        return item.id
      })
      const treeSelectedStr = treeSelectedArr.join()
      if (
        this.treeSelectedData.filter((item) => item.id == treeNode.id).length >
        0
      ) {
        return true
      }
      if (treeSelectedStr.indexOf('custom') > -1) {
        // 自定义分析页面类目选择器
        if (this.treeDomId == 'CustomAnalyzeCatTreeId') {
          if (this.isSameCustomCat(treeNode)) {
            return true
          } else {
            this.$message.warning(this.$t('search.custom_other_tooltip'))
            return false
          }
        } else {
          this.$message.warning(this.$t('search.custom_tooltip'))
          return false
        }
      }
      if (
        treeNode.id.indexOf('custom') > -1 &&
        this.treeSelectedData.length > 0
      ) {
        this.$message.warning(this.$t('search.custom_other_tooltip'))
        return false
      }
    },
    /**
     *
     */
    isSameCustomCat(treeNode) {
      const parentId = treeNode.getPath()[0].id
      const selectParentId = this.treeSelectedData[0].cid_path[0]
      return parentId == selectParentId
    },
    limitCat_30(node) {
      // 用户取消已选择的类目,允许继续执行
      if (
        this.treeSelectedData.find((ele) => ele.id == node.id) ||
        node.check_Child_State == 1
      ) {
        return true
      }
      // 选择类目超过30个,禁止操作并报错
      if (this.treeSelectedData.length >= 30) {
        this.$message.warning(this.$t('search.cat_more_30'))
        return false
      } else {
        return true
      }
    },
    treeSearchBeforeCheck(treeId, treeNode) {
      // 限制用户选择少于30个类目
      if (!this.limitCat_30(treeNode)) {
        return false
      }
      const treeSelectedArr = this.treeSelectedData.map((item) => {
        return item.id
      })
      const treeSelectedStr = treeSelectedArr.join()
      if (
        this.treeSelectedData.filter((item) => item.id == treeNode.id).length >
        0
      ) {
        return true
      }
      if (treeSelectedStr.indexOf('custom') > -1) {
        this.$message.warning(this.$t('search.custom_tooltip'))
        return false
      }
      if (
        treeNode.id.indexOf('custom') > -1 &&
        this.treeSelectedData.length > 0
      ) {
        this.$message.warning(this.$t('search.custom_other_tooltip'))
        return false
      }
    },
    treeSearchOnCheck(event, treeId, treeNode) {
      const node = this.catTreeObj.getNodeByParam('id', treeNode.id)
      this.catTreeObj.checkNode(node, treeNode.checked, true)
      this.treeSelectedData = []
      let ignoreIds = []
      this.catTreeObj.getCheckedNodes().forEach((item) => {
        const { path, cid_path } = this.computeNodePath(item)
        const catInfo = {
          _id: item._id ? item._id : item.id,
          custom: item.custom,
          name: item.label,
          is_parent: item.is_parent,
          sub_name: item.sub_name,
          id: item.id,
          fix_id: item.id,
          top_id: item.id,
          path,
          cid_path,
          // fix_platform: 'all',
          // checked: false,
          // open: false,
          // pId: '0',
          // path: ["女装/女士精品"],
          // sub_name: "",
          // support_plat_name: '淘宝',
          // top_id: '16'
        }
        // 该节点被全选且父节点未被选中,则子节点id不传入
        if (item.check_Child_State == 2 && ignoreIds.indexOf(item.id) == -1) {
          // 若oldIgnoreIds没有值，不影响之前的逻辑
          let idIndex = -2
          if (this.oldIgnoreIds.length > 0) {
            idIndex = this.oldIgnoreIds.findIndex((i) => i == item.id)
          }
          if (!(item.children.length == 1 && item.children[0].checked)) {
            this.treeSelectedData.push(catInfo)
            ignoreIds.push(...this.getChdNodesIds(item))
          } else if (
            (item.id == treeNode.id || idIndex == -1) &&
            item.children.length == 1 &&
            item.children[0].checked
          ) {
            this.treeSelectedData.push(catInfo)
            ignoreIds.push(...this.getChdNodesIds(item))
          }
        } // 父节点不是全选,子节点没有children
        else if (
          item.check_Child_State == -1 &&
          ignoreIds.indexOf(item.id) == -1
        ) {
          this.treeSelectedData.push(catInfo)
        }
        // return catInfo
      })
      this.oldIgnoreIds = ignoreIds
      if (this.$store.state.user.customCat.length > 0) {
        this.computedTimeRangeDebounce()
      }
      this.$emit('change', this.treeSelectedData)
      this.topSearchCheckedNodes(event, treeId, treeNode)
    },
    topSearchCheckedNodes(event, treeId, treeNode) {
      treeNode.getPath().forEach((node) => {
        this.topNode(event, treeId, node)
      })
      const catTreeObj1 = $.fn.zTree.getZTreeObj(this.treeDomId)
      const _treeNode = catTreeObj1.getNodeByParam('id', treeNode.id)
      this.topCheckedNodes(event, this.treeDomId, _treeNode)
      const catTreeObj2 = $.fn.zTree.getZTreeObj(treeId)
      catTreeObj2.selectNode(treeNode, true, false)
    },
    startWorker(cats, cat_list) {
      let lang = localStorage.getItem('language')
        ? localStorage.getItem('language')
        : this.$store.state.user.isOversea
        ? 'zh'
        : 'zh'
      let languageMode = this.$store.state.user.languageMode
      let fenxiLanguageMode = this.$store.state.user.fenxiLanguageMode
      const actions = [
        {
          message: 'transformPickerOptions',
          func: function (
            cats,
            childrenList,
            cat_level_list,
            cat_list,
            plat,
            lang,
            languageMode,
            fenxiLanguageMode
          ) {
            return _transformPickerOptions(
              cats,
              childrenList,
              cat_level_list,
              cat_list,
              plat
            )
            function _transformPickerOptions(
              _cats,
              _childrenList,
              cat_level_list,
              cat_list,
              plat
            ) {
              let catArr = []
              for (let key in _cats) {
                let catId = _cats[key]
                if (catId == '0' || !_childrenList[catId]) {
                  continue
                }
                if (cat_level_list == 'all') {
                  let {
                    category_id,
                    is_parent,
                    chd,
                    // name,
                    // name_en,
                    // category_name,
                    // category_name_en,
                    only_show,
                    level,
                    parent_id,
                  } = _childrenList[catId]
                  const catNameStr = getCatName(
                    _childrenList[catId],
                    lang,
                    languageMode,
                    fenxiLanguageMode
                  )
                  let catCid = category_id ? category_id : catId
                  const custom =
                    _childrenList[catId].custom === undefined
                      ? false
                      : _childrenList[catId].custom + ''
                  const subName = _childrenList[catId].sub_name || ''
                  // if (plat == 'jd' && level == 4) {
                  //   console.log(parent_id)
                  //   catCid = `${parent_id}_${catCid}`
                  // }
                  let cat_tip = ''
                  let _cat = {
                    id: catCid,
                    label: catNameStr,
                    disabled: only_show,
                    is_parent,
                    custom,
                    sub_name: subName,

                    // fix_id: catCid,
                    // fix_platform: 'all',
                    // checked: false,
                    // open: false,
                    // pId: ['0'],
                    // path: ["女装/女士精品"],
                    // sub_name: "",
                    // support_plat_name: '淘宝',
                    // top_id: '16'
                  }
                  if (languageMode) {
                    if (languageMode == 'system') {
                      cat_tip = _childrenList[catId].name_source || ''
                    } else if (languageMode == 'source') {
                      cat_tip = getCatName(_childrenList[catId], lang, 'system')
                    } else if (languageMode == 'both') {
                      cat_tip = getCatName(_childrenList[catId], lang, 'both')
                    }
                    _cat['cat_tip'] = cat_tip
                  }
                  if (location.origin.includes('fenxi') && lang == 'zh') {
                    // if (true && lang == 'en') {
                    if (fenxiLanguageMode == 'zh') {
                      cat_tip = _childrenList[catId].name || ''
                    } else if (fenxiLanguageMode == 'en') {
                      cat_tip = getCatName(
                        _childrenList[catId],
                        lang,
                        'system',
                        'en'
                      )
                    } else if (fenxiLanguageMode == 'both') {
                      cat_tip = getCatName(
                        _childrenList[catId],
                        lang,
                        'both',
                        'both'
                      )
                    }
                    _cat['cat_tip'] = cat_tip
                  }
                  if (custom) {
                    _cat._id = _childrenList[catId]._id
                  }

                  if (is_parent) {
                    let _children = _transformPickerOptions(
                      chd,
                      _childrenList,
                      cat_level_list,
                      cat_list,
                      plat
                    )
                    if (_children.length) {
                      _cat.children = _children
                    }
                  }
                  catArr.push(_cat)
                } else {
                  if (_childrenList[catId]) {
                    let {
                      category_id,
                      is_parent,
                      chd,
                      name,
                      only_show,
                      level,
                      parent_id,
                    } = _childrenList[catId]
                    const catNameStr = getCatName(
                      _childrenList[catId],
                      lang,
                      languageMode,
                      fenxiLanguageMode
                    )
                    let catCid = category_id ? category_id : catId
                    // if (plat == 'jd' && level == 4) {
                    //   catCid = `${parent_id}_${catCid}`
                    // }

                    only_show = check_permission(
                      catId,
                      _childrenList,
                      cat_list
                    )[1]
                    let has_priv = check_permission(
                      catId,
                      _childrenList,
                      cat_list
                    )[0]
                    let _cat = null
                    const custom =
                      _childrenList[catId].custom === undefined
                        ? false
                        : _childrenList[catId].custom + ''
                    const subName = _childrenList[catId].sub_name || ''
                    if (has_priv) {
                      _cat = {
                        id: catCid,
                        label: catNameStr,
                        disabled: only_show == true,
                        is_parent: is_parent,
                        custom,
                        sub_name: subName,
                      }
                      if (custom) {
                        _cat._id = _childrenList[catId]._id
                      }
                    } else {
                      continue
                    }
                    if (languageMode) {
                      if (languageMode == 'system') {
                        cat_tip = _childrenList[catId].name_source || ''
                      } else if (languageMode == 'source') {
                        cat_tip = getCatName(
                          _childrenList[catId],
                          lang,
                          'system'
                        )
                      } else if (languageMode == 'both') {
                        cat_tip = getCatName(_childrenList[catId], lang, 'both')
                      }
                      _cat['cat_tip'] = cat_tip
                    }
                    if (is_parent) {
                      let _children = _transformPickerOptions(
                        chd,
                        _childrenList,
                        cat_level_list,
                        cat_list,
                        plat
                      )
                      if (_children.length) {
                        _cat.children = _children
                      }
                    }
                    // 如果前面类目父类已经添加,就不要重复添加
                    let index = catArr.findIndex((item) => {
                      return item.id == _cat.id
                    })
                    if (index > -1) {
                      continue
                    } else {
                      catArr.push(_cat)
                    }
                  } else {
                    continue
                  }
                }
              }
              return catArr
            }
            function getCatName(
              { name, name_en, category_name, category_name_en, name_source },
              lang,
              languageMode,
              fenxiLanguageMode
            ) {
              if (location.origin.includes('fenxi') && lang == 'en') {
                // if (true && lang == 'en') {
                let cat_name = ''
                if (fenxiLanguageMode == 'zh') {
                  cat_name = cat_name += name || category_name
                } else if (fenxiLanguageMode == 'en') {
                  cat_name = cat_name +=
                    name_en || category_name_en || name || category_name
                } else if (fenxiLanguageMode == 'both') {
                  cat_name = cat_name +=
                    (name || category_name) +
                    '/' +
                    (name_en || category_name_en || name || category_name)
                }
                if (cat_name == 'undefined') {
                  cat_name = ''
                }
                return cat_name
              } else {
                let cat_name = ''
                if (lang == 'zh') {
                  cat_name = cat_name += name || category_name
                } else {
                  cat_name = cat_name +=
                    name_en || category_name_en || name || category_name
                }
                if (languageMode) {
                  if (languageMode == 'system') {
                  } else if (languageMode == 'source') {
                    cat_name = name_source ? name_source : cat_name
                  } else {
                    cat_name = `${cat_name}${
                      name_source ? '/' + name_source : ''
                    }`
                  }
                }
                if (cat_name == 'undefined') {
                  cat_name = ''
                }
                return cat_name
              }
            }
            function check_permission(cid, __childrenList, cat_list) {
              let pids = get_parents(cid, __childrenList)
              let has_priv = false,
                only_show = true
              //1.父类目是否有权限
              pids.forEach(function (pid, i) {
                let cat = __childrenList[pid]
                let level = cat.level
                if (cat_list['level' + level]) {
                  cat_list['level' + level].forEach(function (o, j) {
                    if (!o) return
                    if (o.cat_id == pid || o.cat_id == '0') {
                      has_priv = true
                      only_show = false
                    }
                  })
                } else {
                  ;[].forEach(function (o, j) {
                    if (o.cat_id == pid) {
                      has_priv = true
                      only_show = false
                    }
                  })
                }
              })
              //2.子类目有权限的，需展示父类目
              if (!has_priv) {
                Object.keys(cat_list).forEach(function (v) {
                  cat_list[v].forEach(function (o, i) {
                    if (!o) return
                    let parents = get_parents(o.cat_id, __childrenList)
                    if (parents.indexOf(cid) >= 0) {
                      has_priv = true
                      only_show = true
                    }
                  })
                })
              } else {
                only_show = false
              }

              return [has_priv, only_show]
            }
            function get_parents(cid, ___childrenList) {
              let cat = ___childrenList[cid]
              let pids = [cid]
              if (cat && cat.custom) {
                while (cat && cat.parent_key) {
                  pids.push(cat.parent_key)
                  cat = ___childrenList[cat.parent_key]
                }
              } else {
                while (cat && cat.parent_id != '0') {
                  pids.push(cat.parent_id)
                  cat = ___childrenList[cat.parent_id]
                }
              }

              return pids
            }
          },
        },
      ]
      let worker = this.$worker.create(actions)
      let postMessageActionName = 'transformPickerOptions'
      let plat = ''
      if (this.showCats == 2 || this.showCats == 4) {
        plat = 'jd'
      }
      return worker.postMessage(postMessageActionName, [
        cats,
        this.catsData.result.childrenList,
        cat_list,
        cat_list,
        plat,
        lang,
        languageMode,
        fenxiLanguageMode,
      ])
    },
    // // 加平台需修改
    getCatData(plat) {
      let name = '',
        urlName = ''
      if (plat == TB_PLAT) {
        name = 'cache_cat_taobao'
        urlName = 'TB_CAT_URL'
      } else if (plat == JD_PLAT) {
        name = 'cache_cat_jd'
        urlName = 'JD_CAT_URL'
      } else if (plat == CUSTOM_PLAT) {
        name = 'cache_custom_cats'
        urlName = 'CUSTOM_CAT_URL'
      } else if (plat == CUSTOM_PLAT_2) {
        name = 'cache_mojing_cats'
        urlName = 'CUSTOM_CAT_URL_2'
      } else if (plat == CUSTOM_PLAT_3) {
        name = 'bigcats_makeup_custom'
        urlName = 'CUSTOM_CAT_URL_3'
      } else if (plat == CUSTOM_PLAT_4) {
        name = 'bigcats_water_custom'
        urlName = 'CUSTOM_CAT_URL_4'
      } else if (plat == DOUYIN_PLAT) {
        name = 'cache_douyin_cats'
        urlName = 'DOUYIN_CATS_URL'
      } else if (
        plat.indexOf('amazon') > -1 ||
        plat.indexOf('shopee') > -1 ||
        plat.indexOf('lazada') > -1 ||
        plat.indexOf('tiktok') > -1 ||
        plat.indexOf('ozon') > -1 ||
        plat.indexOf('hktvmall') > -1
      ) {
        name = 'cache_cat_oversea_' + plat
        urlName = 'OVERSEA_' + plat.toUpperCase() + '_CATS_URL'
      }
      return new Promise((resolve, reject) => {
        if (!window[name]) {
          const url = CAT_URL[urlName]
          const s = document.createElement('script')
          s.type = 'text/javascript'
          s.src = url
          document.body.appendChild(s)
          s.onload = function () {
            resolve(window[name])
          }
        } else {
          resolve(window[name])
        }
      })
    },
    searchChange() {
      const value = this.filterText
      if (value.trim() !== '') {
        this.isSearchTree = true
        this.doSearch(value)
      } else {
        this.isSearchTree = false
      }
    },
    doSearch(searchWord) {
      let _this = this
      /*异步执行低频率执行解决性能问题*/
      if (_this.searchTime) {
        clearTimeout(_this.searchTime)
      }
      _this.searchTime = setTimeout(function () {
        let serverSearch =
          _this.options.serverSearch ||
          function () {
            return new Promise(function (resolve, reject) {
              resolve(null)
            })
          }
        serverSearch(searchWord).then(function (serverSearchData) {
          let searchcatTreeObj = _this.catTreeObj
          let nodeList = searchcatTreeObj.getNodesByFilter(function (node) {
            return _this.ifHit(node, searchWord, serverSearchData)
          }) //通过关键字模糊搜索
          let datasMap = {}
          $(nodeList).each(function (index, item) {
            item.isSearchNode = true
            datasMap[item['id']] = item
          })
          if (_this.options.searchShowParent) {
            $(nodeList).each(function (index, node) {
              _this.loadParents(node, datasMap)
            })
          }
          let _zNodes = _.values(datasMap)
          _this.initSearchTree(_zNodes, searchWord, serverSearchData)
        })
      }, 200)
    },

    ifHit(node, keyWord, serverSearchData) {
      serverSearchData = serverSearchData || []
      let _this = this
      if (
        _this.options.searchKeys instanceof Array &&
        _this.options.searchKeys.length > 0
      ) {
        let flag = false
        $(_this.options.searchKeys).each(function (index, item) {
          if (item.startsWith('like_')) {
            if (node[item.replace('like_', '')].indexOf(keyWord) != -1) {
              flag = true
              return
            }
          } else if (item.startsWith('eq_')) {
            if (node[item.replace('eq_', '')] === keyWord) {
              flag = true
              return
            }
          }
        })
        return flag
      } else {
        return (
          node[_this.setting.data.key.name]
            .toLowerCase()
            .indexOf(keyWord.toLowerCase()) != -1 ||
          _.includes(serverSearchData, node.id)
        )
        // return node[_this.setting.data.key.name].indexOf(keyWord) != -1 || _.includes(serverSearchData, node.id);
      }
    },
    loadParents(node, datasMap) {
      let parent = node.getParentNode()
      if (parent) {
        let copyparent = $.extend({}, parent)
        copyparent.children = null
        copyparent.open = true
        datasMap[parent['id']] = copyparent
        this.loadParents(parent, datasMap)
      } else {
        return
      }
    },
    initSearchTree(data) {
      let _this = this
      this.searchCatTreeObj = $.fn.zTree.init(
        $('#' + this.treeDomSearchId),
        _this.searchSetting,
        data
      )
    },
    // 加平台需修改
    checkPlatFenxi(plat) {
      switch (plat) {
        case 'global':
        case 'cshop':
          return 'taobao'
        case 'tmall':
        case 'chaoshi':
        case 'nochaoshi':
        case 'tmallhk':
        case 'notmallhk':
        case 'tmzyzmd':
        case 'tmqjd':
        case 'tmallhk_gfzy':
        case 'all':
          return 'tmall'

        case 'jd':
        case 'jd_only_self':
        case 'jd_exclude_self':
        case 'jd_hk':
        case 'jd_home_self':
        case 'jd_home_pop':
        case 'jd_not_home_self':
        case 'jd_not_home_pop':
          return 'jd'
        case 'amazon_us':
        case 'amazon_de':
        case 'amazon_es':
        case 'amazon_fr':
        case 'amazon_it':
        case 'amazon_uk':
        case 'amazon_jp':
        case 'amazon_ae':
        case 'amazon_sa':
        case 'amazon_br':
        case 'amazon_mx':
          return 'amazon'
        default:
          return plat
      }
    },
    checkDefaultCats() {
      setTimeout(() => {
        if (Object.keys(this.catTreeObj).length > 0) {
          const defaultNodes = []
          this.defaultCats.forEach((item) => {
            const node = this.catTreeObj.getNodeByParam('id', item.id)
            defaultNodes.unshift(node)
          })
          defaultNodes.forEach((item, index) => {
            this.topCheckedNodes(null, this.treeDomId, item)
          })
          this.catTreeObj.checkAllNodes(false)
          this.defaultCats.forEach((item, index) => {
            const node = this.catTreeObj.getNodeByParam('id', item.id)
            let _node
            if (node) {
              this.catTreeObj.checkNode(node, true, true)
            } else {
              const sub_name = item.sub_name ? item.sub_name : ''
              _node = this.catTreeObj.getNodeByParam('id', sub_name + item._id)
              if (_node) {
                this.catTreeObj.checkNode(_node, true, true)
              }
            }
          })
          this.treeSelectedData = []
          let ignoreIds = []
          this.catTreeObj.getCheckedNodes().forEach((item) => {
            const { path, cid_path } = this.computeNodePath(item)
            const catInfo = {
              _id: item._id ? item._id : item.id,
              custom: item.custom,
              name: item.label,
              is_parent: item.is_parent,
              sub_name: item.sub_name,
              id: item.id,
              fix_id: item.id,
              top_id: item.id,
              path,
              cid_path,
              // fix_platform: 'all',
              // checked: false,
              // open: false,
              // pId: '0',
              // path: ["女装/女士精品"],
              // sub_name: "",
              // support_plat_name: '淘宝',
              // top_id: '16'
            }
            // 该节点被全选且父节点未被选中,则子节点id不传入
            if (
              item.check_Child_State == 2 &&
              ignoreIds.indexOf(item.id) == -1
            ) {
              this.treeSelectedData.push(catInfo)
              ignoreIds.push(...this.getChdNodesIds(item))
            } // 父节点不是全选,子节点没有children
            else if (
              item.check_Child_State == -1 &&
              ignoreIds.indexOf(item.id) == -1
            ) {
              this.treeSelectedData.push(catInfo)
            }
            // return catInfo
          })
          this.$emit('change', this.treeSelectedData)
          this.expandCheckedNodes()
          if (this.$store.state.user.customCat.length > 0) {
            this.computedTimeRangeDebounce()
          }
          if (this.allDisabled) {
            const nodes = this.catTreeObj.getNodes()
            nodes.forEach((node) => {
              this.catTreeObj.setChkDisabled(node, true, false, true)
            })
          }
        } else {
          this.checkDefaultCats()
        }
      }, 10)
    },
    expandCheckedNodes() {
      this.catTreeObj.cancelSelectedNode()
      this.catTreeObj.getCheckedNodes().forEach((item, index) => {
        if (item.getPath()[0].id == '124868003') {
          return
        }
        item.getPath().forEach((ele, _index, arr) => {
          this.catTreeObj.expandNode(
            ele,
            _index !== arr.length - 1,
            false,
            index == 0 && item.id == ele.id ? false : true
          )
        })
        if (index == 0) {
          setTimeout(() => {
            this.catTreeObj.selectNode(item, true, false)
          }, 300)
        } else {
          this.catTreeObj.selectNode(item, true, true)
        }
      })
    },
    computeNodePath(node) {
      const path = []
      const cid_path = []
      const pathes = node.getPath()
      pathes.forEach((item) => {
        path.push(item.label)
        cid_path.push(item.id)
      })
      return { path, cid_path }
    },
    hasPermission(cid) {
      const __childrenList = this.catsData.result.childrenList
      let cat_list = this.showCatsList[this.showCats]
      return check_permission(cid, __childrenList, cat_list)
      function check_permission(cid, __childrenList, cat_list) {
        let pids = get_parents(cid, __childrenList)
        let has_priv = false,
          only_show = true
        //1.父类目是否有权限
        pids.forEach(function (pid, i) {
          let cat = __childrenList[pid]
          let level = cat.level
          if (cat_list['level' + level]) {
            cat_list['level' + level].forEach(function (o, j) {
              if (o.cat_id == pid) {
                has_priv = true
                only_show = false
              }
            })
          } else {
            ;[].forEach(function (o, j) {
              if (o.cat_id == pid) {
                has_priv = true
                only_show = false
              }
            })
          }
        })
        //2.子类目有权限的，需展示父类目
        if (!has_priv) {
          Object.keys(cat_list).forEach(function (v) {
            cat_list[v].forEach(function (o, i) {
              let parents = get_parents(o.cat_id, __childrenList)
              if (parents.indexOf(cid) >= 0) {
                has_priv = true
                only_show = true
              }
            })
          })
        } else {
          only_show = false
        }

        return [has_priv, only_show]
      }
      function get_parents(cid, ___childrenList) {
        let cat = ___childrenList[cid]
        let pids = [cid]
        if (cat && cat.custom) {
          while (cat && cat.parent_key) {
            pids.push(cat.parent_key)
            cat = ___childrenList[cat.parent_key]
          }
        } else {
          while (cat && cat.parent_id != '0') {
            pids.push(cat.parent_id)
            cat = ___childrenList[cat.parent_id]
          }
        }

        return pids
      }
    },
  },
  mounted() {
    if (this.defaultCats.length > 0) {
      this.debounceCheckCats()
    }
    this.loadCats()
  },
}
</script>

<style lang="less" scoped>
.fenxi-cat-tree {
  text-align: left;

  .bi-link {
    min-width: 180px;
    text-align: center;
    color: @fenxiPrimaryColor;
    cursor: pointer;
    text-decoration: none;
  }

  .bi-link:hover {
    text-decoration: none;
  }

  .el-button--primary {
    background-color: @fenxiPrimaryColor;
    border-color: @fenxiPrimaryColor;
    margin-top: 6px;
    padding: 5px;
    width: 28px;
    height: 28px;
    border-radius: 0;
    opacity: 0.9;
  }

  .el-input__inner {
    border-radius: 0;
  }
  ul {
    padding: 0;
  }
  /deep/ .tip_popper__arrow {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    list-style: none;
    cursor: pointer;
    line-height: 1.2;
    word-wrap: break-word;
    color: #ffffff;
    white-space: nowrap;
    box-sizing: border-box;
    background: white;
    font-family: 'iconfont' !important;
    font-size: 14px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 6px;
    left: -6px;
    border-right-color: #303133;
    border-left-width: 0;
    top: 11px;
    &::after {
      border-bottom-color: rgba(0, 0, 0, 0);
      border-bottom-style: solid;
      border-bottom-width: 5px;
      border-left-color: rgba(0, 0, 0, 0);
      border-left-style: solid;
      border-left-width: 0px;
      border-right-color: rgb(48, 49, 51);
      border-right-style: solid;
      border-right-width: 5px;
      border-top-color: rgba(0, 0, 0, 0);
      border-top-style: solid;
      border-top-width: 5px;
      bottom: -5px;
      box-sizing: border-box;
      content: ' ';
      display: block;
      height: 10px;
      left: 1px;
      position: absolute;
      width: 5px;
    }
  }
}
</style>

<style lang="less">
.fenxi-cat-tree {
  .el-input--mini .el-input__inner {
    border-radius: 0;
  }
  .el-checkbox {
    line-height: 30px;
    color: @fenxiPrimaryColor;
    font-weight: normal;
  }
}
</style>
