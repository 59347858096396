 <template>
  <div class="temp_datatable"
       v-loading="loading  || errorItem.text=='RepeatRequest'"
       v-message="errorItem">
    <datatable v-bind="$data"
               :key="key">
    </datatable>
  </div>
</template>
<script>
import Vue from 'vue'
Vue.component('transStringComp', {
  props: {
    temp: String,
    datas: Object,
  },
  render(h) {
    const com = Vue.extend({
      template: `<div>${this.temp}</div>`,
      props: {
        datas: Object,
      },
    })

    return h(com, {
      props: {
        datas: this.datas,
      },
    })
  },
})
import components from './comps/'
export default {
  components: components,
  name: 'VueDatatable', // `name` is required as a recursive component
  props: {
    /*row: {
      type: Object,
      default: () => {},
    },*/
    options: {
      type: Object,
      default: () => {
        return {
          selection: undefined,
          showIndex: false,
          HeaderSettings: true,
          supportBackup: true,
          supportNested: true,
          tblClass: 'table-bordered',
          tblStyle: 'color: #666',
          Pagination: true,
          pageSizeOptions: [5, 10, 15, 20],
          columns: [],
          params: {},
          query: {},
          _xprops: {},
          totalIndex: false,
          // totalIndex： 按照排名展示序号
        }
      },
    },
    getDataMethod: {
      type: Function,
      default: () => {},
    },
    passData: {
      type: Object,
      default: () => {
        return {
          data: [],
          total: 0,
        }
      },
    },
    defaultSelection: {
      type: Array,
      default: () => {
        return []
      },
    },
  }, // from the parent FriendsTable (if exists)
  data() {
    /* const amINestedComp = !!this.row  */
    const otherOption = {
      selection: this.selection ? this.selection : undefined,
      HeaderSettings: this.HeaderSettings ? this.HeaderSettings : true,
      supportBackup: this.supportBackup ? this.supportBackup : true,
      supportNested: this.supportNested ? this.supportNested : true,
      tblClass: this.tblClass ? this.tblClass : 'table-bordered',
      tblStyle: this.tblStyle ? this.tblStyle : 'color: #666',
      Pagination: this.Pagination ? this.Pagination : true,
      pageSizeOptions: this.pageSizeOptions
        ? this.pageSizeOptions
        : [10, 50, 100],
      //   columns: this.options.showIndex
      //     ? [
      //         {
      //           title: 'index',
      //           field: 'index',
      //           label: '排名',
      //           visible: 'true',
      //         },
      //       ]
      //     : [],
    }
    return {
      errorItem: { type: 'info', text: '' },
      loading: true,
      //   newPassData: this.passData,
      data: [],
      total: 0,
      order_by: '',
      //   selection: [],
      //  summary: {},
      query: this.options.query
        ? this.options.query
        : {} /* amINestedComp ? { uid: this.row[this.childUidName] } : {}, */,
      ...otherOption,
      ...this.options,
      xprops: {
        eventbus: new Vue(),
        suggestCount: {},
        ...this.options._xprops,
      },
    }
  },
  watch: {
    query: {
      handler(val) {
        // customComponents = this.components
        if (!this.options.hasPassData) {
          this.handleQueryChangeDebounce()
        } else {
          this.loading = true
          function compare(p, isDesc) {
            //这是比较函数
            return function (m, n) {
              var a = p == 'time' ? new Date(m[p]) : m[p]
              var b = p == 'time' ? new Date(n[p]) : n[p]
              if (!isDesc) {
                return a - b //升序
              } else {
                return b - a
              }
            }
          }
          if (
            this.newPassData &&
            this.newPassData.data &&
            this.newPassData.data.length
          ) {
            this.loading = false
            this.data = this.newPassData.data.sort(
              compare(val.sort, val.order == 'desc')
            )
            if (this.newPassData.data.length > val.limit) {
              this.data = this.data.slice(val.offset, val.offset + val.limit)
            }
          }
          //   if (val.suggest || val.suggest == null) {
          //     this.handleQueryChange()
          //   }
        }

        // 排序改变需要通知父组件,修改下载链接参数
        this.$emit('QueryChange', val)
      },
      deep: true,
    },
    'options.params': {
      handler(newVal, oldVal) {
        if (newVal.cat == -2) {
          return
        }
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.$set(this, 'query', {
            order: this.query.order
              ? this.query.order
              : newVal.desc
              ? 'desc'
              : null,
            sort: this.query.sort ? this.query.sort : newVal.order_by,
            limit: newVal.page_size,
            offset: (newVal.page - 1) * newVal.page_size,
            aspect: this.query.aspect ? this.query.aspect : newVal.aspect,
          })
        }
      },
      deep: true,
      immediate: true,
    },
    'options.columns': {
      handler(newVal) {
        this.columns = this.options.columns
        if (this.options.showIndex) {
          if (
            !this.options.columns.find((item) => {
              return item.field == 'index'
            })
          ) {
            if (this.options.fixHeaderAndSetBodyMaxHeight) {
              this.options.columns.unshift({
                title: this.$t('baseInfo.ranking'), //'排名',
                field: 'index',
                label: this.$t('baseInfo.ranking'),
                visible: 'true',
                thStyle: {
                  paddingLeft: '16px',
                  textAlign: 'center',
                  minWidth: '70px',
                  width: '70px',
                },
                tdStyle: {
                  paddingLeft: '8px',
                  textAlign: 'center',
                  minWidth: '70px',
                  width: '70px',
                },
              })
            } else {
              this.options.columns.unshift({
                title: this.$t('baseInfo.ranking'),
                field: 'index',
                label: this.$t('baseInfo.ranking'),
                visible: 'true',
                thStyle: {
                  paddingLeft: '16px',
                  textAlign: 'center',
                  minWidth: '50px',
                  width: '50px',
                },
                tdStyle: {
                  paddingLeft: '8px',
                  textAlign: 'center',
                  minWidth: '50px',
                  width: '50px',
                },
              })
            }
          }
        }
      },
    },
    newPassData: {
      handler(newVal, oldVal) {
        this.handleData()
      },
    },
    total: {
      handler(newVal, oldVal) {
        this.$emit('TotalChanged', this.total)
      },
    },
    order_by: {
      handler(newVal, oldVal) {
        this.$emit('CurrentChange', 1)
      },
    },
    selection: {
      handler(newVal, oldVal) {
        console.log(newVal)
        let selectionData = []
        if (newVal.length > 0) {
          selectionData = newVal.map(({ index }) => index)
        }
        this.$emit('SelectionChange', {
          page: this.options.params.page,
          selectionData: selectionData,
          data: newVal,
        })
      },
    },
  },
  computed: {
    newPassData() {
      return this.passData
    },
    newDefaultSelection() {
      return this.defaultSelection
    },
    key() {
      return JSON.stringify(this.options)
    },
  },
  methods: {
    handleQueryChange() {
      this.xprops.plat = this.options._xprops.plat
      this.loading = true
      this.$set(this.errorItem, 'type', 'info')
      this.$set(this.errorItem, 'text', '')
      let params = {}
      //  params = this.params
      params = {
        ...this.options.params,
        desc:
          this.query.order === 'desc'
            ? true
            : this.query.order == 'asc'
            ? false
            : null,
        order_by: this.query.sort ? this.query.sort : null,
        page_size: this.query.limit,
        page: this.query.offset / this.query.limit + 1,
      }
      if (params.cat == -2) {
        return
      }

      // cat为-1 ,表示用户未选择类目
      if (params.cat == -1) {
        this.data = []
        this.total = 0
        this.loading = false
        this.$message({
          type: 'warning',
          message: '请选择类目',
        })
        return
      }
      for (const key in params) {
        if (
          params[key] === null ||
          params[key] === undefined ||
          params[key] === ''
        ) {
          delete params[key]
        } else {
          if (typeof params[key] == 'object') {
            params[key] = params[key]
          } else {
            params[key] = params[key].toString()
          }
        }
      }
      // order_by 与 传入的参数同步
      if (this.xprops.linkPath == 'fxLink' && params.order_by == 'item_count') {
        //分析+的店铺分析列表按商品数排序时需处理
        params.order_by = 'item_num'
      }
      if (this.xprops.linkPath == 'fxLink' && params.order_by == 'shop_count') {
        //分析+的店铺分析列表按商品数排序时需处理
        params.order_by = 'shop_num'
      }
      this.order_by = params.order_by
      // if (
      //   params.order_by != null &&
      //   params.order_by != '' &&
      //   params.page != '1' &&
      //   this.order_by != params.order_by
      // ) {
      //   this.order_by = params.order_by
      //   return
      // }
      this.getDataMethod(params)
        .then((res) => {
          this.$emit('echartChanged', res)
          this.loading = false
          if (res.status == 'ok') {
            this.data = res.result.data
            if (this.newDefaultSelection.length > 0) {
              let obj = this.newDefaultSelection.find(
                (item) => item.page == params.page
              )
              if (obj && obj.selectionData.length > 0) {
                this.$nextTick(
                  () =>
                    (this.selection = obj.selectionData.map(
                      (index) => this.data[index - 1]
                    ))
                )
              } else {
                // this.$nextTick(() => this.selection = [])
              }
            }
            if (res.result.suggest_count) {
              this.xprops.suggestCount = res.result.suggest_count
            }
            if (this.options.showIndex) {
              // totalIndex： 按照排名展示序号
              if (this.options.totalIndex) {
                const page = params.page
                const size = params.page_size
                this.data = res.result.data.map((item, index) => {
                  let new_item = { ...item }
                  new_item.index = index + 1 + (page - 1) * size
                  return new_item
                })
              } else {
                this.data = res.result.data.map((item, index) => {
                  let new_item = { ...item }
                  new_item.index = index + 1
                  return new_item
                })
              }
            }
            this.total = res.result.count
            //  this.$emit('TotalChanged', this.total)
            this.$set(this.errorItem, 'type', 'info')
            this.$set(this.errorItem, 'text', '')
          } else {
            this.total = 0
            this.$set(this.errorItem, 'type', 'warning')
            this.$set(this.errorItem, 'text', res.backMsg)
          }
        })
        .catch((e) => {
          console.log(e)
          this.data = []
          this.total = 0
          this.loading = false
          this.$set(this.errorItem, 'type', 'error')
          this.$set(this.errorItem, 'text', e.backMsg)
        })
        .finally(() => {
          this.loading = false
        })
      // mockData(this.query).then(({ rows, total, summary }) => {
    },
    alertSelectedUids() {
      alert(this.selection.map(({ uid }) => uid))
    },
    handleData() {
      if (!this.options.hasPassData) {
        // this.handleQueryChange()
        if (!this.options.showIndex) {
          this.data = this.newPassData.data
        } else {
          if (
            !this.options.columns.find((item) => {
              return item.field == 'index'
            })
          ) {
            if (this.options.fixHeaderAndSetBodyMaxHeight) {
              this.options.columns.unshift({
                title: this.$t('baseInfo.ranking'), //'排名',
                field: 'index',
                label: this.$t('baseInfo.ranking'),
                visible: 'true',
                thStyle: {
                  paddingLeft: '16px',
                  textAlign: 'center',
                  minWidth: '70px',
                  width: '70px',
                },
                tdStyle: {
                  paddingLeft: '8px',
                  textAlign: 'center',
                  minWidth: '70px',
                  width: '70px',
                },
              })
            } else {
              this.options.columns.unshift({
                title: this.$t('baseInfo.ranking'), //'排名',
                field: 'index',
                label: this.$t('baseInfo.ranking'),
                visible: 'true',
                thStyle: {
                  paddingLeft: '16px',
                  textAlign: 'center',
                  minWidth: '50px',
                  width: '50px',
                },
                tdStyle: {
                  paddingLeft: '8px',
                  textAlign: 'center',
                  minWidth: '50px',
                  width: '50px',
                },
              })
            }
          }
        }
      } else {
        if (!this.options.showIndex) {
          this.data = this.newPassData.data ? this.newPassData.data : []
        } else {
          if (
            !this.options.columns.find((item) => {
              return item.field == 'index'
            })
          ) {
            if (this.options.fixHeaderAndSetBodyMaxHeight) {
              this.options.columns.unshift({
                title: this.$t('baseInfo.ranking'), //'排名',
                field: 'index',
                label: this.$t('baseInfo.ranking'),
                visible: 'true',
                thStyle: {
                  paddingLeft: '16px',
                  textAlign: 'center',
                  minWidth: '70px',
                  width: '70px',
                },
                tdStyle: {
                  paddingLeft: '8px',
                  textAlign: 'center',
                  minWidth: '70px',
                  width: '70px',
                },
              })
            } else {
              this.options.columns.unshift({
                title: this.$t('baseInfo.ranking'),
                field: 'index',
                label: this.$t('baseInfo.ranking'),
                visible: 'true',
                thStyle: {
                  paddingLeft: '16px',
                  textAlign: 'center',
                  minWidth: '70px',
                  width: '70px',
                },
                tdStyle: {
                  paddingLeft: '8px',
                  textAlign: 'center',
                  minWidth: '70px',
                  width: '70px',
                },
              })
            }
          }
          if (this.newPassData && this.newPassData.data) {
            this.data = this.newPassData.data.map((item, index) => {
              let newItem = { ...item }
              newItem.index = index + 1
              return newItem
            })
          } else {
            this.data = []
          }
        }
        this.total = this.newPassData.total ? this.newPassData.total : 0
        this.loading = false
      }
    },
    refresh() {
      this.handleQueryChangeDebounce()
    },
  },
  mounted() {
    this.handleData()
  },
  created() {
    if (this.$eventBus) {
      this.$eventBus.$on('loadingCats', (val) => {
        if (val) {
          this.loading = val
        }
      })
    }
    this.xprops.eventbus.$on('NAME_CLICK', (data) => {
      this.$emit('NameClick', data)
    })
    this.xprops.eventbus.$on('Name_UPDATE', (data) => {
      this.$emit('NameUpdate', data)
    })
    this.xprops.eventbus.$on('Suggest_UPDATE', (data) => {
      this.$emit('SuggestUpdate', data)
    })
    this.xprops.eventbus.$on('ACTION_CLICK', (data) => {
      this.$emit('ActionClick', data)
      //this.displayBy = displayBy
    })
    this.xprops.eventbus.$on('ACTION_DELETE', (data) => {
      this.$emit('ActionDelete', data)
      //this.displayBy = displayBy
    })
    this.xprops.eventbus.$on('FOLLOW_CLICK', (data) => {
      this.$emit('FollowClick', data)
    })
    this.xprops.eventbus.$on('SELECT_CHANGE', (data) => {
      this.$emit('SelectChange', data)
    })
    this.xprops.eventbus.$on('CHANGE_CUSTOM_RULE', (param) => {
      this.$emit('ChangeCustomRule', param)
    })
    this.xprops.eventbus.$on('SUGGEST_COUNT_CHANGE', (data) => {
      this.xprops.suggestCount = data.suggestCount
    })

    this.handleQueryChangeDebounce = _.debounce(this.handleQueryChange, 500)
  },
}
</script>
<style scoped lang="less" >
.temp_datatable {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  /deep/ th {
    padding: 8px 2px;
  }
  /deep/ td {
    padding: 8px 2px;
  }
  .w-240 {
    width: 240px;
  }
  .alignRight {
    text-align: end;
  }
}
</style>
