module.exports = {
  placeholder: {
    enter: "Please type in your favorite brand",
  },
  echarts: {
    data_source: "Data Sources",
    company: "Magic Mirror Market Intelligence",
  },
  sideMenu: {
    search: "Search",
    subscription: "Subscriptions",
    follow: "Followings",
    concept: "Chance",
    report: "Research",
    set: "Setting",
    custom: "Customizations",
    pivot: "PivotTables",
    globalData: "Tmall Sales Days Data",
    preList: "Presale Ranking",
    saleList: "Sales Ranking",
    back_cmi: "Back to CMI",
    ai: "AI cost efficiency",
  },
  topHeader: {
    support: "Contact Us",
    logout: "Sign out",
    login: "Sign in",
    contact: "Scan the code to contact the after-sales staff",
    doc: "Product Documents",
    theme: "theme",
    orange: "orange",
    blue: "blue",
  },
  search: {
    plat: "Platform",
    cat: "Category",
    search_cat_keyword: "Search category",
    select_all: "Select all",
    no_category: "There are no categories to choose from",
    dont_support_cat_select:
      "Comment jump page does not support category selection",
    title: "Product Title",
    brand: "Brand Name",
    time: "Time",
    shop: "Store Name/ID",
    avg: "Average Price",
    sales: "Monthly Sales Volume",
    sold: "Monthly Sales Value",
    load_more: "Load more",
    load_attr: "Load product attributes",
    view_more: "Click here to chance to see more",
    not_find: "No high-growth concepts were found in this category",
    confirm: "Confirm",
    cancel: "Cancel",
    brand_placeholder: "Enter brand name and search",
    item_attr: "Properties",
    item_attr_rela: "Relationships",
    search: "Search",
    attr_set_error: "Common top attribute interval setting error",
    select_placeholder: "Please select",
    please_select_date: "Please select date",
    please_select_time: "Please select time",
    please_select_plat: "Please select plat",
    please_select_attr: "Please select attr",
    attr_placeholder: "Please enter property filter",
    title_placeholder: "Please enter the product title keyword search",
    edit_placeholder: "Please enter the custom translation result",
    word_placeholder:
      "Automatically recommend relevant keywords after selecting a category",
    shop_placeholder: "Enter store name keyword or store ID to search",
    moneyUnit: "¥",
    countUnit1: "10K ",
    countUnit2: "M ",
    and: "and",
    or: "or",
    sold_unit: "Pieces",
    to: "to",
    top_attr: "TOP attribute",
    full_attr: "Full attribute analysis",
    filing_attr: "Filing attribute",
    translate: "Translate",
    title_question_1:
      'Support for using "and", "or", "not" as filter conditions,',
    title_question_2: "separated by spaces,",
    title_question_3:
      'such as "Xiaomi and Huawei" indicates that the product title contains both "Xiaomi" and "Huawei",',
    title_question_4:
      '"Xiaomi or Huawei" indicates that the product title contains "Xiaomi", "Huawei", or both,',
    title_question_5:
      '"Xiaomi not Huawei" indicates that the product title contains "Xiaomi" but not "Huawei".',
    avg_question_1: "Input 100~200",
    avg_question_2: "Indicates that the filter condition is 100",
    avg_question_3: "Average goods price",
    sold_question_3: "Product monthly sales volumn",
    sales_question_3: "Product monthly sales values",
    time_error_message: "The end time cannot be less than the start time!",
    custom_tooltip: "Customization cannot be multi-selected",
    cat_null_tooltip: "Category cannot be empty!",
    cat_least_one: "At least one category, title, and brand",
    attr_least_one: "At least one attribute filtering criterion!",
    month_picker_tooltip:
      "The current account is a trial account. After formal cooperation, more time period data can be viewed.",
    custom_other_tooltip:
      "Customized categories and other categories cannot be selected together.",
    cat_more_30:
      "You have exceeded the limit of selecting 30 categories. Please select again!",
    module_title_cats: "Platform & Category",
    module_title_title: "Search by Title",
    module_title_filter: "Basic Filters",
    module_title_find: "New Product Discovery",
    module_title_attr: "Filter by Attribute",
    shelf_time: "Shelf Time",
    filing_date: "Filing Time",
    time_empty: "clear",
    advanced_option: "Advanced Options",
    attr_forbid_tooltip:
      "Historical data before 2021 has been taken offline. If you need to access it, please contact sales.",
    search_item_id: "Search for Product ID/link",
    item_id: "Product ID",
    item_id_placeholder:
      "Please enter the product ID or product URL, one per line, separated by line breaks, up to 100 items",
    modify_success: "Modified successfully",
    searching: "Searching...",
  },
  plat: {
    taobao: "Taobao",
    global: "Taobao Global",
    "taobao&tmall": "Taobao + Tmall",
    tmall: "Tmall",
    tmallhk: "Tmall Global",
    chaoshi: "Tmall Supermarket",
    tmallhk_gfzy: "Tmall Global direct-sale Store",
    nochaoshi: "Tmall Not Tmall Supermarket",
    notmallhk: "Tmall not-Tmall Global",
    tmqjd: "Tmall Flagship Store",
    tmzyzmd: "Tmall Flagship Store",
    jd: "JD",
    jd_only_self: "JD self-operated Store",
    jd_exclude_self: "JD Non self-operated Store",
    jd_hk: "JD Global",
    jd_home_self: "JD Domestic self-operated Store",
    jd_home_pop: "JD Domestic POP",
    jd_not_home_self: "JD Overseas self-operated Store",
    jd_not_home_pop: "JD Overseas POP",
    douyin: "Douyin",
    shopee_br: "Shopee Brazil",
    shopee_cl: "Shopee Chile",
    shopee_co: "Shopee Colombia",
    shopee_es: "Shopee Spain (discontinued)",
    shopee_fr: "Shopee France (discontinued)",
    shopee_id: "Shopee Indonesia",
    shopee_in: "Shopee India (discontinued)",
    shopee_mx: "Shopee Mexico",
    shopee_my: "Shopee Malaysia",
    shopee_ph: "Shopee the Philippines",
    shopee_pl: "Shopee Poland",
    shopee_sg: "Shopee Singapore",
    shopee_th: "Shopee Thailand",
    shopee_tw: "Shopee Taiwan, China",
    shopee_vn: "Shopee Vietnam",
    lazada_my: "Lazada Malaysia",
    lazada_sg: "Lazada Singapore",
    lazada_th: "Lazada Thailand",
    lazada_ph: "Lazada the Philippines",
    lazada_id: "Lazada Indonesia",
    lazada_vn: "Lazada Vietnam",
    tiktok_gb: "tiktok UK",
    tiktok_id: "tiktok Indonesia",
    tiktok_my: "tiktok Malaysia",
    tiktok_ph: "tiktok the Philippines",
    tiktok_sg: "tiktok Singapore",
    tiktok_th: "tiktok Thailand",
    tiktok_us: "tiktok US",
    tiktok_vn: "tiktok Vietnam",
  },
  topHead: {
    select_option_category: "Category",
    select_option_category_holder:
      "You can enter Title keywords or product links to search",
    select_option_brand: "Brand",
    select_option_brand_holder: "Please select",
    select_option_postKeyWords: "PostKeywords",
    select_option_postKeyWords_holder: "Please enter the content",
    rules_message_search: "Enter at least one brand or post content",
    my_attention: "MyAttention",
    login_label: "SignIn",
    login_out: "SignOut",
    Chinese: "中文",
    English: "English",
    data_language_chinese: "数据展示：中文",
    source_language_chinese: "数据展示：源语言",
    data_language_english: "Data Language: English",
    source_language_english: "Data Language: Original Language",
    custom_settings: "Custom settings: RMB(=",
    custom_exchange_rate: "Custom exchange rate",
    confirmation_of_change: "Confirmation of change",
    latest_exchange_rate: "Latest exchange rate",
    title: "Moojing Analysis+",
    // po_request: "Request for Explanation/Training",
    // po_describe:
    //   "Encountered any issues while using our product? Scan the QR code to apply for product explanation or training.",
  },
  mainHead: {
    eidtTooltip: "Edit search criteria",
    platList_weibo: "Micro Blog",
    platList_douyin: "Tik Tok",
    platList_xhs: "Red Booklet",
    cshop: "CSHOP",
    "cshop,tmall": "CSHOP and TMALL",
    "cshop,tmall,jd": "CSHOP and TMALL and JD",
    "cshop,tmall,douyin": "CSHOP and TMALL and Tik Tok",
    "cshop,jd": "CSHOP and JD",
    "tmall,jd": "TMALL and JD",
    global: "GLOBAL",
    tmall: "TMALL",
    tmallhk: "TMALLHK",
    chaoshi: "TMALL (supermarket)",
    tmallhk_gfzy: "TMALLHK(Official direct sale)",
    nochaoshi: "TMALL (non-supermarket)",
    notmallhk: "Non-Tmall Global",
    tmqjd: "TMALL(flagship store)",
    tmzyzmd: "TMALL(franchise/exclusive store)",
    jd: "JD",
    jd_only_self: "JD(self)",
    jd_exclude_self: "JD(3rd party)",
    jd_hk: "JD_GLOBAL",
    jd_home_self: "JD(home self)",
    jd_home_pop: "JD(home pop)",
    jd_not_home_self: "JD(not home self)",
    jd_not_home_pop: "JD(not home pop)",
    amazon: "Amazon",
    amazon_us: "Amazon US",
    amazon_de: "Amazon DE",
    amazon_es: "Amazon ES",
    amazon_fr: "Amazon FR",
    amazon_it: "Amazon IT",
    amazon_uk: "Amazon UK",
    amazon_jp: "Amazon JP",
    amazon_ae: "Amazon AE",
    amazon_sa: "Amazon SA",
    amazon_br: "Amazon Brazil",
    amazon_mx: "Amazon Mexico",
    shopee: "Shopee",
    shopee_vn: "Shopee VN",
    shopee_th: "Shopee TH",
    shopee_sg: "Shopee SG",
    shopee_my: "Shopee MY",
    shopee_ph: "Shopee PH",
    shopee_id: "Shopee ID",
    shopee_tw: "Shopee TW, China",
    lazada: "Lazada",
    lazada_vn: "Lazada VN",
    lazada_th: "Lazada TH",
    lazada_sg: "Lazada SG",
    lazada_my: "Lazada MY",
    lazada_ph: "Lazada PH",
    lazada_id: "Lazada ID",
    dateLabel: "Date",
    date_to: "to",
    date_holder: "Select month",
    saveProj: "Save scheme",
    tabs_baseInfo: "BaseInfo",
    tabs_talents: "Talent list",
    tabs_brand: "Brand list",
    tabs_custom: "Custom analysis",
    rules_message_search:
      "Enter at least one brand or category or post content",
    rules_message_search2:
      "At least one of the brand or category or post content is not empty",
    rules_message_range: "Time range cannot be empty",
    message_saveProj_success: "Scheme saved successfully",
    message_saveProj_failed: "Failed to save scheme",
    tabs_summary: "Summary & Trends",
    tabs_catlist: "Category Analysis",
    tabs_brandlist: "Brand Analysis",
    tabs_shopslist: "Store Analysis",
    tabs_itemslist: "Product Analysis",
    tabs_attrlist: "Attribute Analysis",
    tabs_price_dis: "Price Distribution",
    tabs_brand_price_dis: "Brand Price Distribution",
    tabs_item_sku: "SKU Sales",
    tabs_item_sku_attr: "SKU Attribute Analysis",
    tabs_item_filing_info: "Filing Info",
    only_cat_tip: "View data after selecting the category",
    // guide: "Guide",
    // guide_tooltip: "Click the guide button here to open the new user guide.",
  },
  mainHeader: {
    filter_text: "Filter",
    plat: "Platform",
    cat: "Category",
    confirm: "Confirm",
    follow: "Follow",
    followed: "Followed",
    no_discount: "No discount coupon",
    discount_v1: "Coupon model v1",
    full_discount: "Full discount coupon",
    full_discount_v2: "Full discount coupon v2",
    expand: "Expand Filter",
    hide: "Hide Filter",
    tabs_plat: "Plat Analysis",
    tabs_summary: "Summary & Trends",
    tabs_catlist: "Category Analysis",
    tabs_brandlist: "Brand Analysis",
    tabs_shopslist: "Store Analysis",
    tabs_itemslist: "Product Analysis",
    tabs_attrlist: "Properties Analysis",
    tabs_price_dis: "Price Distribution",
    tabs_brand_price_dis: "Brand Price Distribution",
    tabs_item_sku: "SKU Sales",
    tabs_item_sku_attr: "SKU Attribute Analysis",
    tabs_custom_analyze: "Customize Analysis",
    tabs_pivot_analyze: "PivotTable Analysis",
    tabs_consumer: "Consumer Observation",
    consumer_pop_1:
      "The number displayed is the number of operations remaining in e-commerce listening after jumping to e-commerce listening",
    consumer_pop_2:
      "The current number of experiences has been used up, if you need to continue to use please contact the account manager.",
    tabs_filing_product_analyze: "Filing Product Analysis",
    tabs_filing_effect_analyze: "Filing Effect Analysis",
    tabs_filing_ingredient_analyze: "Filing Ingredient Analysis",
    tabs_filing_production_company_analyze: "Filing Company Analysis",
    oversea_insight: "Overseas E-Commerce Insight",
  },
  subscription: {
    plat: "Platform",
    cat: "Category",
    data_time: "Data Start and End Time",
    content_placeholder: "Please enter content",
  },
  attention: {
    name: "Customize name",
    follow: "My filter criteria followings",
    operation: "Operate",
    cancel: "Cancel following",
    click_follow: "Following",
  },
  report: {
    message:
      "At present, the display pre-sales report is for reference only, if you need deep insights and customized analysis to contact relevant salespeople",
    name: "Industry Name",
    report_name: "Pre-sales Report",
    operation: "Operate",
    download: "Download",
    downloading: "Downloading",
  },
  setting: {
    phone_set: "Mobile Number Setting",
    mail_set: "Email Setting",
    primary_number: "Your Primary Mobile Number",
    mail_number: "Your email address",
    second_number: "Your Secondary Mobile  Number",
    delete: "Delete",
    phone_placeholder: "Please enter content",
    sms: "SMS login verification",
    add: "Add mobile number",
    add_mail: "Add email",
    success: "Mobile number added successfully",
    confirm: "Confirm",
    dialog_phone: "Primary Mobile Number",
    dialog_input_placeholder: "Enter verification code",
    verification: "Verification",
    cancel: "Cancel",
    yesterday: "yesterday",
    7: "Nearly 7 days",
    30: "Nearly 30 days",
    90: "Nearly 90 days",
    enter_num: "Enter your phone number",
    enter_correct_num: "Enter the correct phone number",
    enter_correct_mail: "Please enter a valid email",
    first_num: "The first phone number must enable SMS login verification.",
  },
  changePasswordPanel: {
    change_password_title:
      "Change The Password (Only the primary mobile number is available)",
    change_password: "Change password",
    use_latest_cat:
      "When the platform category structure changes, use the latest category structure to track historical data.",
    message_success: "Successfully modified.",
  },
  customList: {
    name: "Name",
    cat: "Category",
    operation: "Operation",
    edit: "Edit",
    delete: "Delete",
    preset: "Preset",
    confirm_delete: "Whether to delete this custom analysis",
    confirm: "Confirm",
    cancel: "Cancel",
    title: "Custom Analysis",
    miss_custom_tooltip: "Missing custom analysis name, please enter.",
    prompt: "Prompt",
    my_custom_title: "My custom analysis",
    inspiration_center: "Inspiration center",
    publish: "Publish",
    cancelPublish: "Cancel publish",
    status: "AI automatically generates name/rule task status",
  },
  pivotList: {
    title: "PivotTable Analysis Management Table",
    name: "Name",
    operation: "Operation",
    edit: "Edit",
    delete: "Delete",
    confirm_delete: "Whether to delete this pivotTable analysis",
    confirm: "Confirm",
    cancel: "Cancel",
    over_70:
      "The number of custom analysis rules has exceeded the limit of the pivot table. If you have analysis needs, please contact the product operation team for help!",
  },
  summary: {
    top10_brands: "Top 10 Brands",
    view_all_brands: "View all brands",
    category_analysis: "Category analysis",
    jump_to_cat: "Jump to category analysis",
    noCatlist: "No category analysis under current filtering conditions",
    noContent:
      "There are no comments or viewpoints under the current filtering criteria",
    jump_to_Itemslist: "Jump to Product Analysis",
    jump_to_consumerInsight: "Jump to Consumer Insight",
    jump_to_chance: "Jump to chance",
    no_concept:
      "The current filtering criteria do not support the concept of high growth",
    comments: "Comments",
    comments_tooltip1:
      "1. Generate comments for the top 1000 products based on the current filtering criteria",
    comments_tooltip2:
      "2. This module only supports listening to product reviews from e-commerce platforms and categories; If you need other product reviews, please contact the data consultant~",
    comments_tooltip3:
      "3. This module can display up to 6 months of product reviews within the selected time range. If you need more historical reviews, please go to the e-commerce platform to listen",
    concept_High: "Concept of high growth",
    concept_tooltip:
      "The current module displays the top 10 high growth concepts in sales revenue for the most recent month and selected category in the selected data time, regardless of other search criteria. To view all high growth concepts, please go to the 'Complete High Growth Ranking'",
    product_title: "Product title",
    product_title_tooltip:
      "Generate top 1000 non Taobao platform sales products based on the current filtering criteria (only apply Taobao platform top products when filtering Taobao platform). If you need to customize product title word clouds, please contact a data consultant~",
    sales: "Sales Value",
    sold: "Sales Volume",
    yoy: "YOY",
    mom: "MOM",
    sales_yoy: "Sales Value YOY",
    sales_mom: "Sales Value MOM",
    sold_yoy: "Sales Volume YOY",
    sold_mom: "Sales Volume MOM",
    avg: "Average",
    shop_count: "Number of Stores",
    item_count: "Number of Commodity",
    sales_growth: "Sales Value Growth",
    sales_growth_mom: "Sales Value MOM",
    sold_growth: "Sales Volume Growth",
    sold_growth_mom: "Sales Volume MOM",
    download: "Download",
    monthly: "Monthly",
    quarter: "Quarterly",
    year: "Annual",
    trend: "Sales Trend",
    time: "Time",
    filing_product: "Filing Products",
    filing_brand: "Filing Brands",
    filing_trend: "Filing Trend",
    filings: "Number of Filings",
    growth: "Growth Rate",
    domestic_ordinary: "Domestic Ordinary",
    domestic_special: "Domestic Special",
    imported_ordinary: "Imported Ordinary",
    imported_special: "Imported Special",
    total: "Total",
    plat: "Plat Trend",
    selected_time_range: "Selected time range",
    selected_time_warning:
      "The selected time range cannot exceed three years, please reselect",
    market_share: "Market Share",
    mean: "Mean",
  },
  priceDis: {
    title: "Price Analysis",
    min_price: "Minimum transaction price",
    max_price: "Maximum transaction price",
    custom_split: "Split",
    custom_split_unit: "",
    range_split_from: "From ¥0, every",
    range_split_to: "to ¥",
    range_split_end: "end",
    price_split_count: "Price range count",
    price_split_from: "From 0-",
    price_split_to: "-",
    generate: "Generate",
    SETPRICERANGE: "Set price range",
    SETPRICEINTERVALS: "Set price intervals",
    SETPRICEPOINTS: "Set price points",
    name: "Name",
    proportion_price_range: "Proportion of sales volume in each price range",
    proportion_price_range_trend:
      "Proportion trend of sales volume in each price range",
    price_sales_trend: "Trend of sales revenue in each price range",
    price_sold_trend: "Sales trend of each price segment",
    proportion_price_sales_trend:
      "Trend of sales proportion in each price range",
    proportion_price_sold_trend:
      "Trend of sales volume proportion in each price range",
    proportion_commodity: "Proportion of Product",
    sales: "Sales Value",
    sold: "Sales Volume",
    sold_and_average: "Sales Volume & Average",
    average: "Average",
    validMessage_1:
      "The interval cannot be less than or equal to the previous item！",
    validMessage_2: "The value of the interval point cannot be empty！",
    validMessage_3: "The price range parameter cannot be empty!",
    range_comment:
      'The price range is left-closed and right-open, for example: "600~800" means "price >= 600" and "price < 800".',
    range: "Range",
    sales_ratio: "Sales Proportion",
    sold_ratio: "Sold Proportion",
    item_ratio: "Product Proportion",
  },
  brandList: {
    title: "Brand Analysis",
    high_growth_title: "High Growth Title",
    chart_definition: "Chart definition",
    brand_name: "Brand",
    now: "Sales for the month",
    last: "Sales in the same period last year",
    beyond_market: "Beyond the market(alpha)",
    yoy_growth: "Year on year growth rate",
    market_growth: "Market growth rate",
    market_average: "Average market price",
    relevant_category: "Relevant category",
    download: "Download",
    sold_ane_brand: "Sales Volume & Quantities of Brands",
    sold: "Sales Volume(10K)",
    brand: "Brands(10K)",
    brand_count: "Brands",
    brand_trend: "Brand Structure Change Trend(Sales Proportion)",
    top5: "TOP5 Brand",
    top6: "TOP6-10 Brand",
    top11: "TOP11-20 Brand",
    top21: "TOP21-50 Brand",
    top51: "TOP51-100 Brand",
    topOther: "Other Brand",
    top10: "TOP 1-10 Brand",
    top20: "TOP 11-20 Brand",
    top30: "TOP 21-30 Brand",
    top_title: "TOP Brand Sales Value & Growth",
    sales: "Sales Value(10K¥)",
    growth: "Sales Value growth",
    cat_growth: "Category Growth",
    data_range: "Data Range",
    current_cat: "Current Category",
    all_level_cat: "Current Category and Its Subcategories at All Levels",
    top_brand: "Top Brands",
    brand_download: "Brand Analysis Download",
    th_share_change: "Market Share Difference YoY",
    high_growth_description:
      "This page displays the high growth brand of the latest month within the selected time range under the current category filtering criteria (independent of other filtering criteria)",
  },
  brandPrice: {
    title: "Brand Price Distribution",
    compare_title: "Market & The Proportion of Top 5 Brands Sales Volume",
    range_title: "Proportion of Top Brand Sales Value in Each Price Range",
    download: "Download",
    downloading: "Downloading...",
    brand: "Brand",
  },
  catList: {
    title: "Category",
    high_growth_title: "High Growth Category",
    chart_definition: "Chart definition",
    horizontal_axis: "X-axis",
    vertical_axis: "Y-axis",
    bubble_size: "Bubble size",
    submit: "Submit",
    display_label: "Display label text",
    high_growth_description:
      "This page displays the high growth categories of the latest month within the selected time range under the current category filtering criteria (independent of other filtering criteria)",
    now: "Sales for the month",
    last: "Sales in the same period last year",
    yoy_growth: "Year on year growth rate",
    market_average: "Average market price",
    cat: "Category",
    route: "Route",
    download: "Download",
    downloading: "Downloading...",
    page_sale: "This Page Category Sales Value",
    sales_bar: "Sales Bar Chart",
    twin_bar_title: "Number of Stores/Products in This Page Category",
  },
  shopList: {
    title: "Store Analysis",
    download: "Download",
    downloading: "Downloading",
    total: "Total",
    monthly: "Monthly",
    method: "Data Option",
    cancel: "Cancel",
    shop_label: "Shop Label",
    shop_type: "Shop Type",
  },
  itemList: {
    title: "Commodity Data",
    data_select: "Data Granularity",
    data_select_commodity: "Commodity",
    data_select_sku: "SKU",
    download: "Download",
    custom_header: "Custom table header",
    product_fields: "Product fields",
    attr_fields: "Attr fields",
    custom_fields: "Custom fields",
    confirm: "Confirm",
    cancel: "Cancel",
    downloading: "Downloading",
    image: "Image",
    product_title: "Product Title",
    sku_name: "SKU Name",
    sales: "Sales Value",
    sold: "Sales Volume",
    price: "Price",
    cross_price: "Crossed Price",
    store_name: "Store Name",
    brand: "Brand",
    store_id: "Store ID",
    city: "Location",
    store_type: "Store Type",
    first_cat: "1-Cate Name",
    second_cat: "2-Cate Name",
    third_cat: "3-Cate Name",
    forth_cat: "4-Cate Name",
    leaf_cat: "Leaf Cate Name",
    brand_id: "Brand ID",
    plat: "Plat",
    item_id: "Item ID",
    item_link: "Product Link",
    comments: "Number of Comments",
    image_link: "Image Link",
    lowest_price: "Lowest Discount Price",
    time: "Time",
    sku_id: "SKU ID",
    online_time: "Shelf Time",
    sku_online_time: "SKU Shelf Time",
    online_time_tooltip:
      "The shelf time be updated starting from January 2022.",
    download_field_select: "Download Field Selection",
    select_all: "Select all",
    attr_field_select: "Attribute Field Selection",
    load_item_attr: "Load commodity attributes",
    comment_analysis: "View comment analysis",
    operation: "Operate",
    sku_count: "SKU Count",
    product_name: "ProductName",
    correct: "Correction",
    correct_complete: "Correction completed",
    correct_text_1: 'What is "correction"?',
    correct_text_2:
      "With correction mode, users can enter/select the correct value in the edit box, or leave it blank to modify the wrong data for subsequent analysis.",
    correct_text_3:
      'Which kinds of data can be corrected through "correction"?',
    correct_text_4:
      "Attribute value, custom label, (cosmetics/skin care category) registered product name.",
    correct_text_5: 'What data time range does "correction" take effect on?',
    correct_text_6: "Time range selected on the current page.",
    only_100: "Only able to query the first 100 pages of data",
    downloadTimeout:
      "Download timed out. Please reduce the scope of the query and try again.",
  },
  attrList: {
    tab_label: "Top Attribute",
    download: "Download",
    across_label: "Attribute Cross Analysis",
    tab_filing: "Filing Attribute",
    attr_quote: "(attr)",
  },
  customAnalysis: {
    name: "Analysis Name",
    select: "Select category and analyse",
    set_rules: "Setting analyse rules",
    upload: "Select & upload",
    title_rule: "Title filter selection",
    help: "Help me customize",
    upload_message: "Upload instructions",
    download_template: "Download template file",
    upload_file: "Upload file",
    select_file: "Select file",
    confirm: "Confirm",
    cancel: "Cancel",
    yes: "Yes",
    no: "No",
    add_attr_rule: "Add attribute rule",
    attr_title_rela: "Attribute and title relationships",
    and: "And",
    or: "Or",
    config_name: "Name",
    item_title_rule: "Product title rule",
    config_attr: "Attribute",
    config_attr_rule: "Attribute rule",
    name_placeholder: "Enter name",
    item_placeholder: "Enter relationships",
    attr_placeholder: "Enter attribute",
    attr_rule_placeholder: "Enter attribute rule",
    submit: "Submit",
    change: "Change",
    sku_or_title: "SKU name or product title",
    sku: "Search only in SKU name",
    sku_null_title:
      "Priority SKU name. When the SKU name is empty, search in the product title",
    select_title: "Search only in product title",
    warn_message: "Choose at least one category",
    submitting:
      "Configurating… You will be redirected immediately after configuration.",
    ai_to_name: "AI helps me generate names",
    ai_to_rule: "AI helps me generate rules",
    term_frequency: "term frequency",
  },
  pivot: {
    list: "Field List",
    row: "Row",
    col: "Column",
    value: "Value",
    filter: "Filter",
    brand_filter: "TOP Brand Filter",
    head_preview: "Header preview",
    data_preview: "Data preview",
    save: "Save",
    change: "Modify",
    drag_message: "Drag fields to the pivot table area",
    not_value_message: "Current field cannot be used as 'value'",
    all: "All",
    download: "Download",
    downloading: "Downloading...",
    pivot_name: "Enter the pivot rule name",
    rule_name: "Rule name",
    rule_name_message: "Enter rule name",
    cancel: "Cancel",
    confirm: "Confirm",
    dialog_title: "Tips",
    download_miss_file: "Download failed, missing file path.",
  },
  itemHeader: {
    filter: "Current Filter",
    cat: "Category",
    select_placeholder: "Select",
  },
  baseInfoCpn: {
    brand_name: "Brand Name",
    shop_name: "Store Name",
    item_attr: "Product Attribute",
    item_attr_message:
      "When multiple months are selected, the product attributes are displayed for more recent months. If there is no attribute data, it may be that the merchant has not filled in the attribute data, or the product sales rank is too low and is not displayed temporarily.",
  },
  skuList: {
    title: "SKU Sales",
    message:
      "Multiple sku names may be the same, which is due to the fact that the merchants re-launch sku products at different times",
    sales_precent: "Percentage of Sales",
    page_price: "Page Price",
  },
  skuAttrList: {
    title: "SKU attribute analysis",
    attr: "Attribute",
  },
  // search: {
  //   title: "Add search criteria",
  //   form_postWord: "PostKeywords",
  //   form_postWord_tip:
  //     "Support and, or, not logic, and word segmentation are separated by spaces; For example: lipstick and (Lip Glaze or lip gloss) not matte",
  //   form_brand: "Brand",
  //   form_brand_holder: "Enter brand keywords",
  //   form_brand_tip:
  //     "For example, searching for the brand 'apple / Apple' will filter out the posts containing 'Apple' or 'Apple' or 'apple / Apple', and the posts representing 'apple / Apple' can be determined through semantic analysis.",
  //   form_category: "Category",
  //   form_cat_tip:
  //     "Please select the category you want to view. Currently, only leaf categories are supported",
  //   form_plat: "Platform",
  //   form_platOption_douyin: "Tik Tok",
  //   form_platOption_weibo: "Micro Blog",
  //   form_platOption_xhs: "Red Booklet",
  //   form_dateRange: "TimeRange",
  //   form_dateRange_holder: "Select month",
  //   form_dateRange_to: "To",
  //   searchBtnLab: "Search",
  // moneyUnit: "US $",
  // countUnit1: "K ",
  // countUnit2: "M ",
  // },
  baseInfo: {
    trend: "Trend",
    posts: "Post",
    posts_toolTip: "Number of All Posts",
    interaction: "Interaction",
    interaction_toolTip: "Comments + Likes + Forwards",
    platTitle: "Platform distribution",
    cloudWordTitle: "WordCloud",
    TalentTitle: "Top 10 list of talents",
    PostListLabel: "Volume ranking",
    IteractionListLabel: "Interaction ranking",
    BrandTitle: "Brand top 10 list",
    postContent: "Original post",
    postType_Forward: "Positive",
    postType_back: "Negative",
    selectedCondition: "Selected Conditions",
    postKeyWordLabel: "Post Keywords:",
    cloudWordLabel: "CloudWord:",
    kolLabel: "KOL:",
    catLabel: "Category：",
    brandLabel: "Brand:",
    brand: "Brand",
    shop: "Shop",
    price: "Price",
    sales: "Sales",
    market_share: "Market Share",
    share_change: "Share Change",
    sold: "Sales volume",
    attr: "Attribute",
    item: "Goods",
    goodsNum: "Goods Num",
    shopNum: "Shop Num",
    average: "Average Price",
    businessName: "Business name",
    registeredAddress: "Registered Address",
    describeScore: "Describe Score",
    serviceScore: "Service Score",
    logisticsScore: "Logistics Score",
    shopwangwang: "Shop wangwang",
    title: "key word",
    MARKET_SHARE: "MARKET SHARE",
    YOY: "SALES YOY",
    QOQ: "SALES MOM",
    SOLD_YOY: "SALES VOLUME YOY",
    SOLD_QOQ: "SALES VOLUME QOQ",
    originPostLink: "Posts Link",
    negativePostPercent: "Proportion of negative posts",
    reposts: "Forwarding",
    comments: "Comments",
    zans: "Likes",
    participle: "Participle",
    frequency: "Frequency",
    photo: "Photo",
    Talent: "Talent",
    plant: "Platform",
    fansNum: "FansNum",
    downLoad: "DownLoad",
    downLoading: "DownLoading...",
    ranking: "Index",
    cloud_nameLabel: "Word",
    cloud_valueLabel: "Count",
    classification: "Classification",
    // headProductText: "Why is it worth buying：",
  },
  TalentList: {
    allPosts: "All Posts",
    forwardPosts: "Positive Posts",
    backPosts: "Negative Posts",
  },
  proj: {
    title: "Preset Project",
    userDefinedName: "Custom name",
    postsKeyWords: "Post keyword",
    brand: "Brand",
    Talent: "Talent",
    plant: "platform",
    date: "Date",
    to: " To ",
  },
  userInfo: {
    enter_phone_title: "Phone number verification",
    enter_email_title: "Email verification",
    enter_phone_placeholder: "Please enter your phone number",
    enter_email_placeholder: "Please enter your email",
    enter_sms: "Please enter the verify code",
    get_sms: "Get verify code",
    verify: "Verify",
    cancel: "Cancel",
  },
  footer: {
    content_one:
      "All data of E-commerce Magic Mirror come from public pages. E-commerce Magic Mirror has no direct connection with Taobao, Tmall, JINGdong,  Amazon and other e-commerce platforms, and is an independent third-party e-commerce data agency  ",
    content_two: "Beijing Taomi Technology Limited Co., LTD. ",
    content_icp: "Beijing ICP 15061734-4 ",
    content_goan: "Beijing network security 11010502037832 ",
    content_add:
      "Data is collected and summarized by the public page, the data caliber is different from that of the platform and other organizations, and it is for reference only. If there is any difference, please refer to the official data released by the platform.",
  },
  ai: {
    insight: "AI Interpret",
    write: "AI Help Write",
  },
  filing: {
    filing_product: "Filing Products",
    filing_brand: "Filing Brands",
    filing_effect: "Filing Effect",
    filing_ingredient: "Filing Ingredient",
    filing_company: "Filing Company",
    filing_company_name: "Registered Company",
    filing_product_name: "Filing Product Name",
    filing_number: "Filing Number",
    filing_product_type: "Product type",
    filing_time: "Filing Time",
    filing_name: "Filing Name",
    filing_name_placeholder: "Enter Filing Name Keyword Search",
    sales: "Sales Value",
    sold: "Sales Volume",
    MARKET_SHARE: "MARKET SHARE",
    sales_yoy: "Sales Value YOY",
    sales_mom: "Sales Value MOM",
    sold_yoy: "Sales Volume YOY",
    sold_mom: "Sales Volume MOM",
    goodsNum: "Goods Num",
    average: "Average",
    shopNum: "Shop Num",
    image: "Image",
    growth: "Growth Rate",
    download: "Download",
    downloading: "Downloading...",
    effect: "Effect",
    brand: "Brand",
  },
  downloadSelection: {
    table: "Download as a table.",
    image: "Download as a picture.",
    bubble: "Download as a bubble Chart",
    lineBar: "Download as a line-bar chart",
    bar: "Download as a bar chart",
    pie: "Download as a pie chart",
  },
  tableTh: {
    concept: "Concept",
    trend_chart: "Sales trends in the past 12 months",
    now: "Sales for the month",
    last: "Sales in the same period last year",
    yoy_growth: "Year on year growth rate",
    average: "Average price of goods",
    commodity_sample: "Commodity sample",
    desc: "description",
  },
  tableThTip: {
    sift: "Sift",
    select_all: "Select all",
    confirm: "confirm",
    suggest: "Suggest",
    not_suggest: "Not suggest",
    recommend: "Recommend",
    not_recommend: "Not recommend",
    trash_can: "Trash can",
    concept_toolTip:
      "The word combination that appears many times in a large number of commodities and has obvious total sales volume is not necessarily a word with existing meaning. Analysts may need to infer and summarize the commodity characteristics and opportunity points revealed by it in combination with conceptual details。",
    trend_chart_toolTip:
      "The total sales of all goods with this concept word in the title from the selected month counting back 12 months",
    now_toolTip:
      "The total sales of all goods with this concept word in the title in the current month",
    last_toolTip:
      "Total sales of all goods with this concept in the title in the current month of last year",
    yoy_growth_toolTip:
      "Total sales in the current month / sales in the same period last year - 1",
    commodity_sample_toolTip:
      "The title contains some commodities (random sampling) of this concept word to help analysts infer the commodity characteristics actually revealed by this concept",
    average_toolTip:
      "The average price of some commodities (random sampling) with this concept word in the title",
    desc_toolTip:
      "Words with high frequency of occurrence extracted from some commodities (random sampling) containing this concept word in the title help analysts infer the commodity characteristics actually revealed by this concept",
    relevant_category_toolTip:
      "All calculations of this record are carried out in this first level category. We only find the concept of high growth under each primary category",
  },
};
