// 注意：一定是 exports，不是 export，否则会报错，报错信息是下列的中的内容不是 string
module.exports = {
  placeholder: {
    enter: "请输入您喜欢的品牌",
  },
  echarts: {
    data_source: "数据来源",
    company: "魔镜洞察",
  },
  sideMenu: {
    search: "自由搜索",
    subscription: "我的订阅",
    follow: "我的关注",
    concept: "高增长榜单",
    report: "行业报告",
    set: "设置",
    custom: "自定义管理",
    pivot: "透视分析管理",
    globalData: "天猫大促数据",
    preList: "预售榜单",
    saleList: "正式销售榜单",
    back_cmi: "返回CMI平台",
    ai: "AI 降本增效",
  },
  topHeader: {
    support: "售后支持",
    logout: "退出",
    login: "登录",
    contact: "扫码联系售后人员",
    doc: "产品文档",
    theme: "主题",
    orange: "橙色",
    blue: "蓝色",
  },
  search: {
    plat: "平台",
    cat: "类目",
    search_cat_keyword: "查关键词对应的一级类目",
    select_all: "全选",
    no_category: "没有类目可供选择",
    dont_support_cat_select: "电商聆听跳转页不支持类目选择",
    title: "商品标题",
    brand: "品牌名称",
    time: "时间",
    shop: "店铺名称/ID",
    avg: "商品均价",
    sales: "商品月销量",
    sold: "商品月销售额",
    load_more: "加载更多",
    load_attr: "加载商品属性",
    view_more: "跳转到增长雷达, 查看更多",
    not_find: "该类目未发现高增长概念",
    confirm: "确认",
    cancel: "取消",
    brand_placeholder: "输入品牌名称关键字搜索",
    item_attr: "商品属性",
    item_attr_rela: "属性间关系",
    search: "搜索",
    attr_set_error: "常用top属性区间设置错误",
    select_placeholder: "请选择",
    please_select_date: "请选择日期",
    please_select_time: "请选择时间",
    please_select_plat: "请选择平台",
    please_select_attr: "请选择属性",
    attr_placeholder: "请输入属性筛选条件",
    title_placeholder: "请输入商品标题关键字搜索",
    edit_placeholder: "请输入自定义翻译结果",
    word_placeholder: "选择类目后自动推荐相关关键词",
    shop_placeholder: "输入店铺名称关键字或店铺ID搜索",
    moneyUnit: "元",
    countUnit1: "万",
    countUnit2: "亿",
    and: "且",
    or: "或",
    sold_unit: "件",
    to: "至",
    top_attr: "TOP属性",
    full_attr: "全属性分析",
    filing_attr: "备案属性",
    translate: "翻译",
    title_question_1: "支持使用“and”、“or”、“not”的筛选条件，",
    title_question_2: "与汉字用空格隔开",
    title_question_3:
      "如： “小米 and 华为”表示商品标题既包含“小米”又包含“华为”",
    title_question_4: "“小米 or 华为 ” 表示商品标题“小米”“华为”至少包含其一",
    title_question_5: "“小米 not 华为” 表示商品标题包含“小米”且不包含“华为”",
    avg_question_1: "输入100~200",
    avg_question_2: "表示筛选条件为100",
    avg_question_3: "商品均价",
    sold_question_3: "商品月销量",
    sales_question_3: "商品月销售额",
    time_error_message: "结束时间不能小于开始时间!",
    custom_tooltip: "定制不能多选",
    cat_null_tooltip: "类目不能为空!",
    cat_least_one: "类目，标题和品牌必须至少填写一个",
    attr_least_one: "至少有一条属性筛选条件！",
    month_picker_tooltip: "当前账号为试用账号，正式合作后可查看更多时间段数据",
    custom_other_tooltip: "定制类目和其他类目不能同时选择",
    cat_more_30: "选择类目超过30个,请重新选择!",
    module_title_cats: "平台 & 类目",
    module_title_title: "搜标题",
    module_title_filter: "基本筛选",
    module_title_find: "商品上新发现",
    module_title_attr: "按属性筛选",
    shelf_time: "上架时间",
    filing_date: "备案时间",
    time_empty: "置空",
    advanced_option: "高级选项",
    attr_forbid_tooltip: "2022年之前历史数据已下线,如需使用,请联系销售",
    search_item_id: "搜商品ID/链接",
    item_id: "商品ID",
    item_id_placeholder: "请输入商品ID或商品url,每行一个,换行符分割,限100个",
    modify_success: "修改成功",
    searching: "搜索中...",
  },
  plat: {
    taobao: "淘宝",
    global: "淘宝全球购",
    "taobao&tmall": "淘宝 + 天猫",
    tmall: "天猫",
    tmallhk: "天猫国际",
    chaoshi: "天猫超市",
    tmallhk_gfzy: "天猫国际官方直营",
    nochaoshi: "天猫非天猫超市",
    notmallhk: "天猫非天猫国际",
    tmqjd: "天猫旗舰店",
    tmzyzmd: "天猫专卖专营店",
    jd: "京东",
    jd_only_self: "京东自营",
    jd_exclude_self: "京东非自营",
    jd_hk: "京东全球购",
    jd_home_self: "京东国内自营",
    jd_home_pop: "京东国内POP",
    jd_not_home_self: "京东海外自营",
    jd_not_home_pop: "京东海外POP",
    douyin: "抖音",
    shopee_br: "shopee巴西站",
    shopee_cl: "shopee智利站",
    shopee_co: "shopee哥伦比亚站",
    shopee_es: "shopee西班牙站(已停运)",
    shopee_fr: "shopee法国站(已停运)",
    shopee_id: "shopee印度尼西亚站",
    shopee_in: "shopee印度站(已停运)",
    shopee_mx: "shopee墨西哥站",
    shopee_my: "shopee马来西亚站",
    shopee_ph: "shopee菲律宾站",
    shopee_pl: "shopee波兰站",
    shopee_sg: "shopee新加坡站",
    shopee_th: "shopee泰国站",
    shopee_tw: "shopee中国台湾站",
    shopee_vn: "shopee越南站",
    lazada_my: "lazada马来西亚站",
    lazada_sg: "lazada新加坡站",
    lazada_th: "lazada泰国站",
    lazada_ph: "lazada菲律宾站",
    lazada_id: "lazada印度尼西亚站",
    lazada_vn: "lazada越南站",
    tiktok_gb: "tiktok英国站",
    tiktok_id: "tiktok印尼站",
    tiktok_my: "tiktok马来西亚站",
    tiktok_ph: "tiktok菲律宾站",
    tiktok_sg: "tiktok新加坡站",
    tiktok_th: "tiktok泰国站",
    tiktok_us: "tiktok美国站",
    tiktok_vn: "tiktok越南站",
  },
  topHead: {
    select_option_category: "类目",
    select_option_category_holder: "请选择类目",
    select_option_brand: "品牌",
    select_option_brand_holder: "请选择",
    select_option_postKeyWords: "帖子关键词",
    select_option_postKeyWords_holder: "请输入内容",
    rules_message_search: "品牌和帖子内容至少输入一个",
    my_attention: "我的关注",
    login_label: "登录",
    login_out: "退出",
    Chinese: "中文",
    English: "English",
    data_language_chinese: "数据展示：中文",
    source_language_chinese: "数据展示：源语言",
    data_language_english: "Data Language: English",
    source_language_english: "Data Language: Original Language",
    custom_settings: "自定义设置：人民币(=",
    custom_exchange_rate: "自定义汇率",
    confirmation_of_change: "确认修改汇率",
    latest_exchange_rate: "最新汇率",
    title: "魔镜分析+",
    // po_request: "申请讲解/培训",
    // po_describe: "产品使用遇到问题！可扫码申请产品讲解或培训哦～",
  },
  mainHead: {
    eidtTooltip: "编辑搜索条件",
    platList_weibo: "微博",
    platList_douyin: "抖音",
    platList_xhs: "小红书",
    cshop: "淘宝",
    "cshop,tmall": "淘宝+天猫",
    "cshop,tmall,jd": "淘宝+天猫+京东",
    "cshop,tmall,douyin": "淘宝+天猫+抖音",
    "cshop,jd": "淘宝+京东",
    "tmall,jd": "天猫+京东",
    global: "淘宝全球购",
    tmall: "天猫",
    tmallhk: "天猫国际",
    chaoshi: "天猫超市",
    tmallhk_gfzy: "天猫国际官方直营",
    nochaoshi: "天猫非天猫超市",
    notmallhk: "天猫非天猫国际",
    tmqjd: "天猫旗舰店",
    tmzyzmd: "天猫专卖专营店",
    jd: "京东",
    jd_only_self: "京东自营",
    jd_exclude_self: "京东非自营",
    jd_hk: "京东全球购",
    jd_home_self: "京东国内自营",
    jd_home_pop: "京东国内POP",
    jd_not_home_self: "京东海外自营",
    jd_not_home_pop: "京东海外POP",
    amazon: "亚马逊",
    amazon_us: "亚马逊美国站",
    amazon_de: "亚马逊德国站",
    amazon_es: "亚马逊西班牙",
    amazon_fr: "亚马逊法国站",
    amazon_it: "亚马逊意大利站",
    amazon_uk: "亚马逊英国站",
    amazon_jp: "亚马逊日本站",
    amazon_ae: "亚马逊阿拉伯联合酋长国站",
    amazon_sa: "亚马逊沙特阿拉伯站",
    amazon_br: "亚马逊巴西站",
    amazon_mx: "亚马逊墨西哥站",
    shopee: "虾皮",
    shopee_vn: "虾皮越南站",
    shopee_th: "虾皮泰国站",
    shopee_sg: "虾皮新加坡站",
    shopee_my: "虾皮马来西亚站",
    shopee_ph: "虾皮菲律宾站",
    shopee_id: "虾皮印尼站",
    shopee_tw: "虾皮中国台湾站",
    lazada: "来赞达",
    lazada_vn: "来赞达越南站",
    lazada_th: "来赞达泰国站",
    lazada_sg: "来赞达新加坡站",
    lazada_my: "来赞达马来西亚站",
    lazada_ph: "来赞达菲律宾站",
    lazada_id: "来赞达印尼站",
    tiktok_gb: "tiktok英国站",
    tiktok_id: "tiktok印尼站",
    tiktok_my: "tiktok马来西亚站",
    tiktok_ph: "tiktok菲律宾站",
    tiktok_sg: "tiktok新加坡站",
    tiktok_th: "tiktok泰国站",
    tiktok_us: "tiktok美国站",
    tiktok_vn: "tiktok越南站",
    dateLabel: "时间",
    date_to: "至",
    date_holder: "选择月",
    saveProj: "保存方案",
    tabs_baseInfo: "基本信息",
    tabs_talents: "达人榜",
    tabs_brand: "品牌榜",
    tabs_custom: "自定义分析",
    tabs_summary: "概要&趋势",
    tabs_catlist: "类目分析",
    tabs_brandlist: "品牌分析",
    tabs_shopslist: "店铺分析",
    tabs_itemslist: "商品分析",
    tabs_attrlist: "属性分析",
    tabs_price_dis: "价格分布",
    tabs_brand_price_dis: "品牌价格分布",
    tabs_item_sku: "SKU销量",
    tabs_item_sku_attr: "SKU属性分析",
    tabs_item_filing_info: "备案信息",
    only_cat_tip: "选择类目后查看",
    // guide: "引导",
    // guide_tooltip: "点击此处的引导按钮打开新手引导。.",
  },
  mainHeader: {
    filter_text: "筛选条件",
    plat: "平台",
    cat: "类目",
    confirm: "确定",
    follow: "关注",
    followed: "已关注",
    no_discount: "不减优惠券",
    discount_v1: "优惠券模型v1",
    full_discount: "完全减优惠券",
    full_discount_v2: "完全减优惠券v2",
    expand: "展开筛选条件",
    hide: "隐藏筛选条件",
    tabs_custom: "自定义分析",
    tabs_plat: "平台分析",
    tabs_summary: "概要&趋势",
    tabs_catlist: "类目分析",
    tabs_brandlist: "品牌分析",
    tabs_shopslist: "店铺分析",
    tabs_itemslist: "商品分析",
    tabs_attrlist: "属性分析",
    tabs_price_dis: "价格分布",
    tabs_brand_price_dis: "品牌价格分布",
    tabs_item_sku: "SKU销量",
    tabs_item_sku_attr: "SKU属性分析",
    tabs_custom_analyze: "自定义分析",
    tabs_pivot_analyze: "透视分析",
    tabs_consumer: "消费者观察",
    consumer_pop_1: "显示的次数为跳转至电商聆听后，在电商聆听中剩余的操作次数",
    consumer_pop_2: "当前体验次数已用完，如需继续使用请联系客户经理。",
    tabs_filing_product_analyze: "备案产品分析",
    tabs_filing_effect_analyze: "备案功效分析",
    tabs_filing_ingredient_analyze: "备案成分分析",
    tabs_filing_production_company_analyze: "生产企业分析",
    oversea_insight: "海外电商洞察",
  },
  subscription: {
    plat: "平台",
    cat: "类目",
    data_time: "数据起止时间",
    content_placeholder: "请输入内容",
  },
  attention: {
    name: "自定义名称",
    follow: "我关注的筛选条件",
    operation: "操作",
    cancel: "取消关注",
    click_follow: "点击关注",
  },
  report: {
    message:
      "目前展示售前报告仅供参考，需要深度洞察和自定义维度分析联系相关销售人员",
    name: "行业名称",
    report_name: "售前报告",
    operation: "操作",
    download: "下载",
    downloading: "下载中...",
  },
  setting: {
    phone_set: "手机号设置",
    mail_set: "邮箱设置",
    primary_number: "您的主手机号",
    mail_number: "您的邮箱号",
    second_number: "您的辅手机号",
    delete: "删除",
    phone_placeholder: "请输入内容",
    sms: "短信验证登录",
    add: "添加手机",
    add_mail: "添加邮箱",
    success: "手机号添加成功",
    confirm: "确 定",
    dialog_phone: "主手机号",
    dialog_input_placeholder: "输入验证码",
    verification: "验证",
    cancel: "取消",
    yesterday: "昨天",
    7: "近7天",
    30: "近30天",
    90: "近90天",
    enter_num: "请输入手机号",
    enter_correct_num: "请输入正确的手机号",
    enter_correct_mail: "请输入正确的邮箱",
    first_num: "第一个手机号必须开启短信登录验证",
  },
  changePasswordPanel: {
    change_password_title: "修改密码 （仅主手机号可用）",
    change_password: "修改密码",
    use_latest_cat: "当平台类目结构发生变化时，使用最新类目结构统计历史数据",
    message_success: "修改成功",
  },
  customList: {
    name: "名称",
    cat: "类目",
    operation: "操作",
    edit: "编辑",
    delete: "删除",
    preset: "预置",
    confirm_delete: "是否删除该条自定义分析",
    confirm: "确 定",
    cancel: "取 消",
    title: "自定义的分析",
    miss_custom_tooltip: "缺少自定义分析命名,请输入",
    prompt: "提示",
    my_custom_title: "我的自定义",
    inspiration_center: "灵感中心",
    publish: "发布",
    cancelPublish: "取消发布",
    status: "AI自动生成名称/规则任务状态",
  },
  pivotList: {
    title: "透视分析列表",
    name: "名称",
    operation: "操作",
    edit: "编辑",
    delete: "删除",
    confirm_delete: "确定是否删除该条透视分析",
    confirm: "确 定",
    cancel: "取 消",
    over_70: "自定义分析规则数超出限制，如有分析需求，请联系产品运营帮您解决！",
  },
  summary: {
    top10_brands: "Top10品牌",
    view_all_brands: "查看全部品牌",
    category_analysis: "类目分析",
    jump_to_cat: "进入类目分析",
    noCatlist: "当前筛选条件下无类目分析",
    noContent: "当前筛选条件下无评论观点",
    jump_to_Itemslist: "进入商品分析",
    jump_to_consumerInsight: "消费者洞察",
    comments: "评论观点",
    comments_tooltip1: "1. 通过当前筛选条件下top1000商品的评论生成",
    comments_tooltip2:
      "2. 该模块仅支持电商聆听产品覆盖平台、类目中的商品评论；如需其它商品评论，请联系数据顾问~",
    comments_tooltip3:
      "3. 该模块最多展示所选时间范围内6个月的商品评论，如需更多历史评论，请前往电商聆听",
    concept_High: "高增长概念",
    jump_to_chance: "完整高增长榜单",
    no_concept: "当前筛选条件不支持高增长概念",
    concept_tooltip:
      "当前模块展示所选数据时间中最近一个月、所选类目条件下的同比增长率top10高增长概念，与其他搜索条件无关；如需查看全部高增长概念，请前往“完整高增长榜单”",
    product_title: "商品标题",
    product_title_tooltip:
      "通过当前筛选条件下非淘宝平台中销售额top1000商品生成（仅筛选淘宝平台时应用淘宝平台top商品），如需定制商品标题词云，请联系数据顾问~",
    sales: "销售额",
    sold: "销量",
    yoy: "同比",
    mom: "环比",
    sales_yoy: "销售额同比",
    sales_mom: "销售额环比",
    sold_yoy: "销量同比",
    sold_mom: "销量环比",
    avg: "商品均价",
    shop_count: "店铺数",
    item_count: "商品数",
    sales_growth: "销售额增速",
    sales_growth_mom: "销售额环比增速",
    sold_growth: "销量增速",
    sold_growth_mom: "销量环比增速",
    download: "下载",
    monthly: "月度",
    quarter: "季度",
    year: "年度",
    trend: "销售趋势",
    time: "时间",
    filing_product: "备案产品数",
    filing_brand: "备案品牌数",
    filing_trend: "备案趋势",
    filings: "备案数",
    growth: "增长率",
    domestic_ordinary: "国产普通",
    domestic_special: "国产特殊",
    imported_ordinary: "进口普通",
    imported_special: "进口特殊",
    total: "合计",
    plat: "平台趋势",
    selected_time_range: "所选时间范围",
    selected_time_warning: "所选时间范围不能超过三年, 请重新选择",
    market_share: "市场份额",
    mean: "均值",
  },
  priceDis: {
    title: "价格分析",
    min_price: "最低成交价",
    max_price: "最高成交价",
    custom_split: "平均分",
    custom_split_unit: "段",
    range_split_from: "从￥0开始，每￥",
    range_split_to: "为一个价格区间，到￥",
    range_split_end: "为止",
    price_split_count: "价格区间数量",
    price_split_from: "价格区间0-",
    price_split_to: "-",
    generate: "生成分布",
    SETPRICERANGE: "自定义成交价范围",
    SETPRICEINTERVALS: "设置价格区间大小",
    SETPRICEPOINTS: "自定义价格区间点",
    name: "名称",
    proportion_price_range: "各价格段销量占比",
    proportion_price_range_trend: "各价格段销量占比趋势",
    price_sales_trend: "各价格段销售额趋势",
    price_sold_trend: "各价格段销量趋势",
    proportion_price_sales_trend: "各价格段销售额占比趋势",
    proportion_price_sold_trend: "各价格段销量占比趋势",
    proportion_commodity: "商品数量占比",
    sales: "销售额",
    sold: "销量",
    sold_and_average: "销量&均价",
    average: "均价(元/件)",
    validMessage_1: "区间点大小不能小于或等于前一项！",
    validMessage_2: "区间点的值不能为空！",
    validMessage_3: "价格区间参数不能为空!",
    range_comment:
      '价格段左闭右开, 如: "600~800" 是指 "价格 >= 600" 且 "价格 < 800"',
    range: "范围",
    sales_ratio: "销售额占比",
    sold_ratio: "销量占比",
    item_ratio: "商品数占比",
  },
  brandList: {
    title: "品牌分析",
    high_growth_title: "高增长品牌",
    chart_definition: "定义图表条件",
    brand_name: "品牌",
    now: "当月总销售额",
    last: "去年同期销售额",
    beyond_market: "超越市场(alpha)",
    yoy_growth: "同比增长率",
    market_growth: "市场增长率",
    market_average: "市场均价",
    relevant_category: "相关类目",
    download: "下载",
    sold_ane_brand: "销量&品牌数",
    sold: "销量(万件)",
    brand: "品牌数(万个)",
    brand_count: "品牌数",
    brand_trend: "品牌结构变化趋势（销售额占比）",
    top5: "TOP5品牌",
    top6: "TOP6-10品牌",
    top11: "TOP11-20品牌",
    top21: "TOP21-50品牌",
    top51: "TOP51-100品牌",
    topOther: "其他品牌",
    top10: "TOP1-10品牌",
    top20: "TOP11-20品牌",
    top30: "TOP21-30品牌",
    top_title: "TOP品牌销售额&同比增速",
    sales: "销售额(万元)",
    growth: "销售额同比增速",
    cat_growth: "类目增速",
    data_range: "数据范围",
    current_cat: "当前类目",
    all_level_cat: "当前类目及各级子类目",
    top_brand: "Top品牌",
    brand_download: "品牌分析下载",
    th_share_change: "同期市场份额差值",
    high_growth_description:
      "本页展示当前类目筛选条件下（与其他筛选条件无关），所选时间范围中最新一个月的高增长品牌",
  },
  brandPrice: {
    title: "价格分布",
    compare_title: "市场整体 & TOP5品牌销量占比对比",
    range_title: "各价格段TOP品牌销量占比",
    download: "下载",
    downloading: "下载中...",
    brand: "品牌",
  },
  catList: {
    title: "相关类目",
    high_growth_title: "高增长类目",
    chart_definition: "定义图表条件",
    horizontal_axis: "横轴",
    vertical_axis: "纵轴",
    bubble_size: "气泡大小",
    submit: "提交",
    display_label: "显示标签文字",
    high_growth_description:
      "本页展示当前类目筛选条件下（与其他筛选条件无关），所选时间范围中最新一个月的高增长类目",
    now: "当月总销售额",
    last: "去年同期销售额",
    yoy_growth: "同比增长率",
    market_average: "市场均价",
    cat: "类目",
    route: "路径",
    download: "下载",
    downloading: "下载中...",
    page_sale: "本页类目销售",
    sales_bar: "销售额柱形图",
    twin_bar_title: "本页类目店铺数/商品数",
  },
  shopList: {
    title: "店铺分析",
    download: "下载",
    downloading: "下载中...",
    total: "总和",
    monthly: "分月",
    method: "汇总方式",
    cancel: "取 消",
    shop_label: "店铺标志",
    shop_type: "店铺类型",
  },
  itemList: {
    title: "商品数据",
    data_select: "数据颗粒度",
    data_select_commodity: "商品级",
    data_select_sku: "SKU级",
    download: "下 载",
    custom_header: "自定义表头",
    product_fields: "商品字段",
    attr_fields: "属性字段",
    custom_fields: "自定义字段",
    confirm: "确 定",
    cancel: "取 消",
    downloading: "下载中...",
    image: "图片",
    product_title: "商品标题",
    sku_name: "sku名称",
    sales: "销售额",
    sold: "销量",
    price: "价格",
    cross_price: "划线价",
    store_name: "店铺名",
    brand: "品牌",
    store_id: "店铺ID",
    city: "发货城市",
    store_type: "店铺类型",
    first_cat: "1级类目名",
    second_cat: "2级类目名",
    third_cat: "3级类目名",
    forth_cat: "4级类目名",
    leaf_cat: "叶子类目名",
    brand_id: "品牌ID",
    plat: "平台",
    item_id: "商品ID",
    item_link: "商品链接",
    comments: "评论数",
    image_link: "图片链接",
    lowest_price: "最低折扣价",
    time: "时间",
    online_time: "上架时间",
    sku_online_time: "SKU 上架时间",
    online_time_tooltip: "上架时间从2022-01起开始更新",
    download_field_select: "下载字段选择",
    select_all: "全选",
    attr_field_select: "属性字段选择",
    load_item_attr: "加载商品属性",
    comment_analysis: "查看评论分析",
    operation: "操作",
    sku_count: "SKU数",
    product_name: "备案产品名称",
    correct: "纠错",
    correct_complete: "纠错完成",
    correct_text_1: "什么是“纠错”？",
    correct_text_2:
      "开启纠错模式后，用户可以在编辑框中自助输入/选择正确的值，或进行置空，以修纠错误的商品数据，方便后续分析。",
    correct_text_3: "哪些数据支持用户自助“纠错”？",
    correct_text_4: "属性值、自定义标签、（彩妆/护肤品类）备案产品名称。",
    correct_text_5: "“纠错”数据对哪些月份生效？",
    correct_text_6: "对当前页面所选的商品时间范围生效。",
    only_100: "仅能查询前100页数据",
    downloadTimeout: "下载超时，请缩小查询数据范围后重试",
  },
  attrList: {
    tab_label: "Top 属性",
    download: "下载",
    across_label: "属性交叉分析",
    tab_filing: "备案属性",
    attr_quote: "(属性)",
  },
  customAnalysis: {
    name: "自定义分析命名",
    select: "选择自定义分析应用的类目",
    set_rules: "配置自定义分析规则",
    upload: "批量上传",
    title_rule: "标题筛选规则选择",
    help: "帮我做自定义",
    upload_message: "批量上传说明",
    download_template: "上传模板下载",
    upload_file: "上传文件",
    select_file: "请选择文件",
    confirm: "确认",
    cancel: "取消",
    yes: "是",
    no: "否",
    add_attr_rule: "添加属性条件规则",
    attr_title_rela: "属性与标题关系",
    and: "且",
    or: "或",
    config_name: "名称",
    item_title_rule: "商品标题规则",
    config_attr: "属性",
    config_attr_rule: "属性规则",
    name_placeholder: "请输入名称",
    item_placeholder: "请输入商品标题规则",
    attr_placeholder: "请选择属性",
    attr_rule_placeholder: "请输入属性名称规则",
    submit: "提交配置",
    change: "修改配置",
    sku_or_title: "SKU名称 或 商品标题",
    sku: "只在SKU名称中搜索",
    sku_null_title: "优先SKU名称,SKU名称为空时在商品标题中搜索",
    select_title: "只在商品标题中搜索",
    warn_message: "请至少选择一个类目",
    submitting: "配置中，配置成功后直接为您跳转",
    ai_to_name: "AI帮我生成名称",
    ai_to_rule: "AI帮我生成规则",
    term_frequency: "词频",
  },
  pivot: {
    list: "字段列表",
    row: "行",
    col: "列",
    value: "值",
    filter: "筛选",
    brand_filter: "TOP品牌筛选",
    head_preview: "生成表头预览",
    data_preview: "生成数据预览",
    save: "保存配置",
    change: "修改配置",
    drag_message: "将字段拖动至数据透视表区域",
    not_value_message: "当前字段不能作为'值'",
    all: "全部",
    download: "下载",
    downloading: "下载准备中...",
    pivot_name: "请输入透视规则名称",
    rule_name: "规则名称",
    cancel: "取 消",
    confirm: "确 定",
    dialog_title: "提示",
    download_miss_file: "下载失败, 缺少文件路径",
  },
  itemHeader: {
    filter: "当前筛选条件",
    cat: "类目",
    select_placeholder: "请选择",
  },
  baseInfoCpn: {
    brand_name: "品牌名称",
    shop_name: "店铺名称",
    item_attr: "商品属性",
    item_attr_message:
      "当时间选择多月时，商品属性展示更近月份的属性信息。若无属性数据，可能是商家未填写属性数据，或商品销量排名过低，暂时不展示。",
  },
  skuList: {
    title: "SKU 销量",
    message: "多个sku名称可能相同，这是因为商家在不同时间重新上架sku商品导致",
    sales_precent: "销售额占比",
    page_price: "页面价",
  },
  skuAttrList: {
    title: "SKU属性分析",
    attr: "属性",
  },
  // search: {
  //   title: "添加搜索条件",
  //   form_postWord: "帖子关键词",
  //   form_postWord_tip:
  //     "支持and、or、not逻辑，和分词用空格隔开；例如：口红 and （唇釉 or 唇彩）not 哑光",
  //   form_brand: "品牌",
  //   form_brand_holder: "输入品牌关键词",
  //   form_brand_tip:
  //     "例如：搜索品牌'苹果/Apple',会筛选出帖子中包含'苹果'或'Apple' 或 '苹果/Apple',且通过语意分析可确定其代表'苹果/Apple' 的帖子。",
  //   form_category: "类目",
  //   form_cat_tip: "请选择需要查看的类目，目前仅支持选择叶子类目",
  //   form_plat: "平台",
  //   form_platOption_douyin: "抖音",
  //   form_platOption_weibo: "微博",
  //   form_platOption_xhs: "小红书",
  //   form_dateRange: "时间范围",
  //   form_dateRange_holder: "选择月",
  //   form_dateRange_to: "至",
  //   searchBtnLab: "搜  索",
  // moneyUnit: "元",
  // countUnit1: "万",
  // countUnit2: "亿",
  // },
  baseInfo: {
    trend: "趋势",
    posts: "声量",
    posts_toolTip: "总发帖数",
    interaction: "互动量",
    interaction_toolTip: "评论数+点赞数+转发数",
    platTitle: "平台分布",
    cloudWordTitle: "词云图",
    TalentTitle: "达人Top10榜单",
    PostListLabel: "声量排行",
    IteractionListLabel: "互动量排行",
    BrandTitle: "品牌Top10榜单",
    postContent: "帖子原文",
    postType_Forward: "正向",
    postType_back: "负向",
    selectedCondition: "已选中的条件",
    postKeyWordLabel: "帖子关键词：",
    cloudWordLabel: "词云：",
    kolLabel: "KOL：",
    catLabel: "类目：",
    brandLabel: "品牌：",
    brand: "品牌",
    shop: "店铺",
    price: "价格",
    sales: "销售额",
    market_share: "市场份额",
    share_change: "份额变化",
    sold: "销量",
    attr: "属性",
    item: "商品",
    goodsNum: "商品数",
    shopNum: "店铺数",
    average: "商品均价",
    businessName: "工商名称",
    registeredAddress: "注册地址",
    describeScore: "描述评分",
    serviceScore: "服务评分",
    logisticsScore: "物流评分",
    shopwangwang: "店铺旺旺",
    title: "关键词",
    MARKET_SHARE: "市场份额",
    YOY: "销售额同比",
    QOQ: "销售额环比",
    SOLD_YOY: "销量同比",
    SOLD_QOQ: "销量环比",
    originPostLink: "原文链接",
    negativePostPercent: "负向声量占比",
    reposts: "转发数",
    comments: "评论数",
    zans: "点赞数",
    participle: "分词",
    frequency: "词频",
    photo: "头像",
    Talent: "达人",
    plant: "平台",
    fansNum: "粉丝数",
    downLoad: "下载",
    downLoading: "下载准备中...",
    ranking: "序号",
    cloud_nameLabel: "词",
    cloud_valueLabel: "数量",
    classification: "分类",
    // headProductText: "为什么值得买：",
  },
  userInfo: {
    enter_phone_title: "手机号验证",
    enter_email_title: "邮箱验证",
    enter_phone_placeholder: "请输入手机号",
    enter_email_placeholder: "请输入邮箱",
    enter_sms: "请输入验证码",
    get_sms: "获取验证码",
    verify: "验证",
    cancel: "取消",
  },
  footer: {
    content_one:
      "魔镜洞察的数据全部来自公开页面，魔镜洞察与淘宝、天猫、京东、亚马逊等电商平台无直接关联，是一家独立的第三方电商数据机构",
    content_two: "@2025 北京淘幂科技有限公司 ",
    content_icp: "京ICP备15061734号-4 ",
    content_goan: "京公网安备11010502037832号 ",
    content_add:
      "数据由公开页面采集并汇总处理，数据口径和平台及其他机构有所不同，仅供参考，如有差异请以平台官方发布数据为准",
  },
  ai: {
    insight: "AI解读",
    write: "AI帮写",
  },
  filing: {
    filing_product: "备案产品数",
    filing_brand: "备案品牌数",
    filing_effect: "备案功效",
    filing_ingredient: "备案成分",
    filing_company: "生产企业",
    filing_product_name: "备案产品名称",
    filing_number: "备案编号",
    filing_product_type: "备案类别",
    filing_time: "备案时间",
    filing_name: "备案产品名称",
    filing_name_placeholder: "输入备案产品名称关键字搜索",
    filing_company_name: "备案企业",
    sales: "销售额",
    sold: "销量",
    MARKET_SHARE: "市场份额",
    sales_yoy: "销售额同比",
    sales_mom: "销售额环比",
    sold_yoy: "销量同比",
    sold_mom: "销量环比",
    goodsNum: "商品数",
    average: "商品均价",
    shopNum: "店铺数",
    image: "图片",
    growth: "增长率",
    download: "下载",
    downloading: "下载中...",
    effect: "功效",
    brand: "品牌",
  },
  downloadSelection: {
    table: "下载为表格",
    image: "下载为图片",
    bubble: "下载为气泡图",
    lineBar: "下载为柱线图",
    bar: "下载为柱状图",
    pie: "下载为饼图",
  },
  tableTh: {
    concept: "概念",
    trend_chart: "近12个月销售趋势",
    now: "当月总销售额",
    last: "去年同期销售额",
    yoy_growth: "同比增长率",
    average: "商品均价",
    commodity_sample: "商品样本",
    desc: "商品描述",
  },
  tableThTip: {
    sift: "筛选",
    select_all: "全选",
    confirm: "确定",
    suggest: "标注",
    not_suggest: "未标注",
    recommend: "推荐",
    not_recommend: "不推荐",
    trash_can: "垃圾桶",
    concept_toolTip:
      "在海量商品中多次出现且有明显总销量的字/词组合，不一定是有现存意思的词汇，可能需要分析师结合概念详情来推断和归纳它所揭示的商品特性和机会点。",
    trend_chart_toolTip:
      "标题中包含这个概念的全部商品在当前月份(含)的近12个月的总销售趋势",
    now_toolTip: "标题中包含这个概念词的全部商品在当前月份的总销售额",
    last_toolTip: "标题中包含这个概念词的全部商品在去年的当前月份的总销售额",
    yoy_growth_toolTip: "当月总销售额 / 去年同期销售额 - 1",
    commodity_sample_toolTip:
      "标题中包含这个概念词的部分商品（随机采样），帮助分析师推断此概念实际揭示的商品特性",
    average_toolTip: "标题中包含这个概念词的部分商品（随机采样）的价格平均值",
    desc_toolTip:
      "标题中包含这个概念词的部分商品（随机采样）中提取出来的出现次数高的词，帮助分析师推断此概念实际揭示的商品特性",
    relevant_category_toolTip:
      "此条记录的所有计算是在这个一级类目当中进行而得到的。我们只在每个一级类目下分别发现高增长概念",
  },
};
