<template>
  <div>
    <el-dialog :visible.sync="dialogVisible"
               custom-class="vip-dialog"
               @close="close"
               width="1100px">
      <template v-if="showContent">
        <slot name="aside">
          <div class="aside-area">
            <span class="right-title">会员权益</span>
            <div class="right-list">
              <span class="list-item"><img src="../assets/pay-dialog-circle.png" />消费者洞察</span>
              <span class="list-item"><img src="../assets/pay-dialog-circle.png" />市场调研</span>
              <span class="list-item"><img src="../assets/pay-dialog-circle.png" />竞品分析</span>
              <span class="list-item"><img src="../assets/pay-dialog-circle.png" />产品研究</span>
            </div>
          </div>
        </slot>
        <div class="right-area">
          <slot>
            <div class="title-area">
              <span class="title">会员详情</span>
              <span class="sub-title">/ 您的提问额度已用完，即刻充值，继续和魔镜AI一起高效洞察市场！</span>
              <div style="float: right">
                <el-button size="mini"
                           style="background: #b73624;color: #fff;border-radius: 5px"
                           @click="jumpToInvite"><i class="el-icon-present"
                     style="font-size: 14px;margin-right: 5px;"></i>免费领取提问机会</el-button>
              </div>
            </div>
            <div class="recharge-area">
              <div class="recharge-card"
                   v-for="item in writingList"
                   :key="item.id"
                   :class="{active: activeId == item.id}"
                   @click="cardChange(item.id)">
                <div class="card-area">
                  <div class="card-tag">限时优惠价</div>
                  <!-- <div class="title">不限提问次数</div> -->
                  <div class="sub-title">原价199</div>
                  <div class="price">
                    <span class="unit">{{item.unit}}</span>
                    <span class="number">{{item.number}}</span>
                    <span class="time">/一个月</span>
                  </div>
                  <div class="sub-price-area">
                    <div class="sub-price">
                      限时优惠,原价199
                    </div>
                  </div>
                  <div style="color: #cacaca;letter-spacing: 1px;font-size: 10px;text-align: center;margin: 5px 0">
                    有效期内最多提问100次
                  </div>
                  <!-- <div class="rights-list">
                    前30名付费用户可获得
                  </div>
                  <div class="rights-list">
                    产品经理
                    <span style="display: inline-block;border-bottom: 1px solid gray; padding-bottom: 2px">1对1在线支持</span>
                  </div> -->
                </div>
              </div>
              <div class="recharge-card"
                   @click="jumpToInvite">
                <div class="card-area">
                  <div class="card-tag"
                       style="background: #b73624;color: #fff;font-weight: 400">邀请有礼</div>
                  <!-- <div class="title">不限提问次数</div> -->
                  <div style="width: 100%;display: flex;justify-content: center;margin-top: 15px">
                    <img src="../assets/gift.png"
                         style="width: 50%"
                         alt="">
                  </div>
                  <div class="sub-price-area">
                    <div style="font-weight: 500">
                      额外免费提问
                    </div>
                  </div>
                  <div style="color: #cacaca;letter-spacing: 1px;font-size: 10px;text-align: center;margin: 5px 0">
                    邀请新用户立享
                  </div>
                  <!-- <div class="rights-list">
                    前30名付费用户可获得
                  </div>
                  <div class="rights-list">
                    产品经理
                    <span style="display: inline-block;border-bottom: 1px solid gray; padding-bottom: 2px">1对1在线支持</span>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="QR-code-area">
              <!-- <el-radio-group v-model="tab"
                              size="small"
                              style="margin-bottom: 10px;">
                <el-radio-button label="wechat"><i class="iconfont icon-weixinzhifu"
                     style="margin-right: 5px;font-size: 14px"></i><span style="height: 16px;line-height: 16px">扫码使用微信支付</span></el-radio-button>
                <el-radio-button label="alipay"><i class="iconfont icon-zhifubaozhifu"
                   style="margin-right: 5px;font-size: 14px"></i><span style="height: 16px;line-height: 16px">支付宝</span></el-radio-button>
              </el-radio-group> -->
              <div class="content"
                   v-loading="QRLoading">
                <div id="wechat-qrcode"
                     v-show="tab == 'wechat' && agreementChecked"
                     width="100px"
                     alt="微信付款码">
                </div>
                <img :src="qrCode"
                     alt=""
                     style="width: 100px; height: 100px"
                     v-show="!agreementChecked">
                <div class="content-pay-area">
                  <i class="iconfont icon-weixinzhifu1"
                     style="color: #57b050"></i>
                  <span style="color: #2D2D2D">微信支付</span>
                </div>

                <!-- <img id="alipay-qrcode"
                   v-show="tab == 'alipay'"
                   width="100px"
                   src="../assets/QRCode.png"
                   alt="支付宝付款码"> -->
              </div>
              <div class="content-desc">
                <div class="pay-desc">
                  <span class="word">微信支付:</span>
                  <span class="unit">¥</span>
                  <span class="number">99</span>
                </div>
                <div class="sub-desc">
                  <span>使用微信扫码付款</span>
                </div>
              </div>
            </div>
          </slot>
          <slot name="footer">
            <div class="product-area">
              <div style="margin-bottom: 20px">
                <el-link href="https://qmctsz2s8t.feishu.cn/docx/NdaOdC8MPo4gi8xk76ZcXD3Unre"
                         type="primary"
                         target="_blank"
                         style="font-size: 12px">【魔镜·镜界AI】产品使用文档</el-link>
              </div>
              <el-popover placement="bottom-start"
                          trigger="hover">
                <div style="width: 200px; height:200px">
                  <img src="../assets/JessieCode.jpeg"
                       alt=""
                       style="width: 100%;height: 100%">
                </div>
                <span class="text"
                      slot="reference">对产品有疑问？联系镜界AI产品经理</span>
              </el-popover>
            </div>
            <div class="footer-area"
                 style="margin-top: 10px; letter-spacing: 1px;">
              <el-checkbox v-model="agreementChecked"
                           style="font-size: 12px">勾选即代表同意会员服务协议
                <el-link href="https://ai.mktindex.com/member.txt"
                         style="height: 20px;line-height: 20px; font-size: 12px; margin-top: -3px"
                         type="primary"
                         target="_blank">《镜界AI会员服务协议》</el-link>
              </el-checkbox>
            </div>
          </slot>
        </div>

      </template>
      <template v-if="showSuccess">
        <div class="payment-success">
          <h1 style="color:#4caf50"><i class="el-icon-circle-check"></i>支付成功!</h1>
          <p>立刻继续和镜界AI一起高效洞察市场，把握商机吧！</p>
          <p> 您可以随时在页面左下角“设置”中查看账号到期时间和问题记录~
          </p>
          <!-- <div class="order-details">
            <h2>订单详情</h2>
            <ul>
              <li><strong>订单编号:</strong> {{}}</li>
              <li><strong>日期:</strong> 2024年5月21日</li>
              <li><strong>金额:</strong> ¥150.00</li>
            </ul>
          </div> -->
          <div class="success-actions">
            <button @click="goToHome">我知道了</button>
          </div>
        </div>
      </template>
      <template v-if="showFail">
        <div class="payment-failure">
          <h1 style="color: #f44336"><i class="el-icon-circle-close"></i>支付失败</h1>
          <p>很遗憾，您的付款无法处理。请再试一次。</p>
          <!-- <div class="error-details">
            <h2>错误详情</h2>
            <p>错误码: 1234</p>
            <p>如果问题仍然存在，请联系客户支持。</p>
          </div> -->
          <div class="actions">
            <button @click="retryPayment">重试</button>
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>


<script>
import QRCode from 'qrcode'
export default {
  name: 'VipDialog',
  data() {
    return {
      dialogVisible: false,
      activeId: 0,
      // 付费选项
      writingList: [],
      QRLoading: false,
      tab: 'wechat',
      showContent: true,
      showSuccess: false,
      showFail: false,
      payId: '',
      payInterval: '',
      // 协议同意
      agreementChecked: true,
      qrCode: require('../assets/qr-code-ma.png'),
    }
  },
  props: {
    getOrderListPromise: {
      type: Function,
      default: () => null,
    },
    getPayDetail: {
      type: Function,
      default: () => null,
    },
    queryPay: {
      type: Function,
      default: () => null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: String,
      default: '',
    },
  },
  computed: {
    loginInfo() {
      return this.$store.state.user.loginInfo
    },
  },
  methods: {
    jumpToInvite() {
      this.$eventBus.$emit('jumpToInvite')
    },
    cardChange(id) {
      this.activeId = id
      this.QRLoading = true
      const host = window.location.hostname
      const domain = host.split('.')[0]
      this.getPayDetail({
        order_type: id,
        order_id: this.orderId,
        username: this.loginInfo.username,
        app_id: domain,
      })
        .then((res) => {
          if (res.status == 'ok') {
            const result = res.result
            this.generateQR('wechat', result.code_url)
            this.payStatusInterval()
            this.QRLoading = false
          }
        })
        .catch((err) => {
          this.QRLoading = false
          console.log(err)
        })
    },
    generateQR(plat, url) {
      switch (plat) {
        case 'wechat': {
          const existingCanvas = document.getElementById('wechat-canvas')
          if (existingCanvas) {
            existingCanvas.remove()
          }
          const canvas = document.createElement('canvas')
          canvas.id = 'wechat-canvas'
          QRCode.toCanvas(canvas, url, { width: 100 }, (error) => {
            if (error) console.error(error)
            document.getElementById('wechat-qrcode').appendChild(canvas)
          })
          break
        }
      }
    },
    // 支付状态轮询
    payStatusInterval(payId) {
      if (this.payInterval) {
        clearInterval(this.payInterval)
        this.payInterval = ''
      }
      this.payInterval = setInterval(() => {
        this.queryPay({
          order_id: this.orderId,
          username: this.loginInfo.username,
        })
          .then((res) => {
            if (res.status == 'ok') {
              const result = res.result
              const tradeState = result.state
              if (tradeState == 'SUCCESS') {
                this.changePayStatus('success')
              }
            }
          })
          .catch((err) => {
            this.changePayStatus('error')
          })
      }, 500)
      // setTimeout(() => {
      //   this.changePayStatus('error')
      // }, 10000)
    },
    changePayStatus(type) {
      switch (type) {
        case 'success':
          {
            clearInterval(this.payInterval)
            this.payInterval = ''
            if (!this.showSuccess) {
              // 如果本身就在支付成功页面, 说明已经支付成功, 就不需要发送事件了.
              this.$emit('on-paySuccess')
            }
            this.showSuccess = true
            this.showContent = false
            this.showFail = false
          }
          break
        case 'error':
          {
            clearInterval(this.payInterval)
            this.payInterval = ''
            this.showSuccess = false
            this.showContent = false
            this.showFail = true
            this.$emit('on-payError')
          }
          break
      }
    },
    goToHome() {
      this.dialogVisible = false
    },
    retryPayment() {
      this.dialogVisible = false
      setTimeout(() => {
        this.dialogVisible = true
      }, 500)
    },
    close() {
      this.showContent = true
      this.showSuccess = false
      this.showFail = false
      this.$emit('payDialogClose')
    },
  },
  watch: {
    visible(newVal) {
      this.dialogVisible = newVal
    },
    tab(newVal) {
      console.log(newVal)
    },
    dialogVisible: {
      handler(newVal) {
        const host = window.location.hostname
        const domain = host.split('.')[0]
        if (newVal) {
          this.QRLoading = true
          if (this.getOrderListPromise) {
            this.getOrderListPromise({
              order_id: this.orderId,
              username: this.loginInfo.username,
              app_id: domain,
            })
              .then((res) => {
                this.QRLoading = false
                if (res.status == 'ok') {
                  const result = res.result
                  this.writingList = result.order_type_list.map((item) => {
                    const temp = {
                      unit: '¥',
                      title: item.desc,
                      number: (item.amount / 100).toFixed(0),
                      amount: item.amount,
                      id: item.order_type,
                    }
                    return temp
                  })
                  this.activeId = this.writingList[0].id
                  // this.payId = this.writingList[0].id
                  this.payStatusInterval()
                  this.generateQR('wechat', result.code_url)
                }
              })
              .catch((err) => {
                this.QRLoading = false
              })
          }
        } else {
          if (this.payInterval) {
            clearInterval(this.payInterval)
            this.payInterval = ''
          }
          this.showContent = true
          this.showSuccess = false
          this.showFail = false
        }
      },
      immediate: true,
    },
    payId: {
      handler(newVal) {
        if (newVal) {
          this.payStatusInterval(newVal)
        }
      },
      immediate: true,
    },
  },
  created() {},
}
</script>

<style lang="less">
.el-dialog__wrapper {
  .vip-dialog {
    font-family: MicrosoftYaHei;
    border-radius: 30px;
    width: 100%;
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      display: flex;
      padding: 0;
      border-radius: 30px;
      .aside-area {
        flex-basis: 200px;
        border-radius: 30px 0 0 30px;
        background-color: #334bf5;
        padding-top: 100px;
        letter-spacing: 2px;
        .right-title {
          display: inline-block;
          width: 100%;
          font-size: 22px;
          color: white;
          text-align: center;
        }
        .right-list {
          display: flex;
          flex-direction: column;
          letter-spacing: 4px;
          color: #fffefe;
          font-size: 14px;
          padding-left: 40px;
          margin-top: 25px;
          font-weight: 100;
          .list-item {
            color: #fffefe;
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            img {
              width: 8px;
              height: 8px;
              margin-right: 20px;
            }
          }
        }
      }
      .right-area {
        padding: 25px;
        .title-area {
          .title {
            color: #2d2d2d;
            font-size: 18px;
            margin-right: 15px;
            font-family: MicrosoftYaHei;
            font-weight: bold;
            letter-spacing: 1px;
          }
          .sub-title {
            font-size: 12px;
            letter-spacing: 1px;
          }
        }
      }
      // border-radius: 50%;
      .recharge-area {
        margin-top: 20px;
        display: flex;
        .recharge-card {
          background: white;
          border: 1px solid #e1e1e1;
          border-radius: 12px;
          cursor: pointer;
          // height: 140px;
          position: relative;
          width: 165px;
          margin-left: 20px;
          .card-area {
            // left: 50%;
            // position: absolute;
            // top: 50%;
            // transform: translate(-50%, -50%);
            // width: 100%;
            // padding-top: 30px;
            .card-tag {
              position: absolute;
              top: -1px;
              left: -1px;
              background-color: #f0ce82;
              border-radius: 8px 0 8px 0;
              color: #6a3002;
              width: 100px;
              text-align: center;
              background: linear-gradient(to right, #f7cc76, #eda73d);
              font-size: 12px;
              height: 20px;
              line-height: 20px;
              font-weight: bolder;
            }
            .title {
              font-size: 18px;
              color: #2d2d2d;
              text-align: center;
              font-weight: bold;
              letter-spacing: 1px;
            }
            .sub-title {
              margin-top: 30px;
              text-align: center;
              font-size: 10px;
              text-decoration: line-through;
              color: #cacaca;
              letter-spacing: 1px;
            }
          }
          // .title {
          //   -webkit-text-fill-color: transparent;
          //   background-clip: text;
          //   -webkit-background-clip: text;
          //   background-image: linear-gradient(
          //     281.06deg,
          //     #813803 4.09%,
          //     #58290a 117.55%
          //   );
          //   font-size: 15px;
          //   font-weight: 500;
          //   margin-bottom: 4px;
          //   text-align: center;
          // }
          .price {
            margin-bottom: 4px;
            text-align: center;
            .unit {
              color: #2f4eff;
              font-size: 18px;
              font-weight: 500;
              line-height: 25px;
              margin-right: 4px;
              font-weight: bolder;
            }
            .number {
              color: #2f4eff;
              font-size: 34px;
              font-weight: bolder;
              line-height: 40px;
              letter-spacing: 2px;
              font-weight: bolder;
            }
            .time {
              letter-spacing: 2px;
            }
          }
          .sub-price-area {
            width: 100%;
            display: flex;
            justify-content: center;
            .sub-price {
              width: 120px;
              background-color: #eda73d30;
              font-size: 12px;
              color: #eda73d;
              text-align: center;
              height: 20px;
              line-height: 20px;
              letter-spacing: 1px;
              border-radius: 5px;
              padding: 0 5px;
            }
          }
          .rights-list {
            color: #7f7f7f;
            font-size: 12px;
            width: 100%;
            text-align: center;
            margin: 10px 0;
          }
        }
        .recharge-card:hover {
          transform: scale(1.1);
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        }
      }
      // .recharge-area .active {
      //   background: linear-gradient(
      //     288.44deg,
      //     #eabc88 -18.63%,
      //     #ebbf8d -11.17%,
      //     #fadbaf 1.29%,
      //     #fff1d4 54.41%,
      //     #fffefa 116.17%
      //   );
      // }
      .document-area {
        // margin-left: 5px;
        margin-top: 10px;
      }
      .product-area {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        font-size: 12px;
        letter-spacing: 1px;
        .text {
          text-decoration: underline;
          cursor: pointer;
          color: #c9c9c9;
          letter-spacing: 1px;
        }
      }
      .QR-code-area {
        background: #fff;
        border: 1px solid #e1e1e1;
        border-radius: 8px;
        color: #676890;
        padding: 12px;
        width: 570px;
        margin-top: 20px;
        display: flex;
        padding-left: 230px;
        justify-content: flex-start;
        .tabs {
          display: flex;
          margin-bottom: 20px;
          .tab {
            background: #fff;
            border: 1px solid #ccc;
            border-radius: 4px;
            padding: 8px;
            cursor: pointer;
            margin-left: 20px;
          }

          .tab.active {
            background: #6182f5;
            color: #fff;
          }
        }
        .content {
          width: 105px;
          padding: 10px 0;
          // height: 120px;
          #wechat-qrcode {
            border: 1px solid #e1e1e1;
          }
          .content-pay-area {
            text-align: center;
            margin-top: 15px;
            i {
              font-size: 18px;
              margin-right: 5px;
            }
            display: flex;
            align-items: center;
            justify-content: center;
            letter-spacing: 2px;
            font-weight: bold;
          }
        }
        .content-desc {
          margin-left: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          .pay-desc {
            margin-bottom: 10px;
            .word {
              color: #2d2d2d;
              font-size: 16px;
              letter-spacing: 1px;
            }
            .unit {
              color: #ff464d;
              font-size: 14px;
              font-weight: bolder;
              letter-spacing: 2px;
            }
            .number {
              color: #ff464d;
              font-size: 22px;
              font-weight: bolder;
              letter-spacing: 2px;
            }
          }
          .sub-desc {
            color: #7f7f7f;
            font-size: 14px;
            letter-spacing: 2px;
          }
        }
      }
    }
    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #606266;
    }
    .el-checkbox__label {
      font-size: 12px;
    }
  }
}
.vip-dialog {
  .payment-success {
    text-align: center;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  .success-icon {
    font-size: 100px;
    color: #4caf50;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .order-details {
    text-align: left;
    margin: 20px 0;
  }
  .order-details h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .order-details ul {
    list-style: none;
    padding: 0;
  }
  .order-details li {
    margin-bottom: 5px;
  }
  .success-actions {
    margin-top: 20px;
  }
  .success-actions button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin: 5px;
  }
  .success-actions button:hover {
    background-color: #45a049;
  }
  .payment-failure {
    text-align: center;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  .failure-icon {
    font-size: 100px;
    color: #f44336;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .error-details {
    text-align: left;
    margin: 20px 0;
  }
  .error-details h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .actions {
    margin-top: 20px;
  }
  .actions button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin: 5px;
  }
  .actions button:hover {
    background-color: #d32f2f;
  }
}
</style>
