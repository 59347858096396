export const COMMENTURL = "https://comment.mktindex.com/";
export const COMMENTTESTURL = "https://commentvuetest.mktindex.com/";
import i18n from "@/components/I18n";
const fenxiLanguage = localStorage.getItem("fenxiLanguage");
const theme = localStorage.getItem("theme");

const DEFAULT_COLORS = [
  "#1253C7",
  "#20CBB9",
  "#FFAE77",
  "#C9DBFA",
  "#A6F1EA",
  "#FFE9DA",
  "#F2F2F2",
];

const ORANGE_COLORS = [
  "#FF7D00",
  "#1BE7FF",
  "#6EEB83",
  "#E4FF1A",
  "#FF5714",
  "#FFE9DA",
  "#F2F2F2",
];

// 通用的颜色配置
// export const COMMENCOLORS = [
//     "#1253C7",
//     "#20CBB9",
//     "#FFAE77",
//     "#C9DBFA",
//     "#A6F1EA",
//     "#FFE9DA",
//     "#F2F2F2",
// ];
// 橙色色块生成网址: https://coolors.co/
export const COMMENCOLORS = getCommonColors();
function getCommonColors() {
  switch (theme) {
    case "default":
      return DEFAULT_COLORS;
    case "orange":
      return ORANGE_COLORS;
    default:
      return DEFAULT_COLORS;
  }
}
// 饼图颜色配色
export const PIECOLORS = [
  "#4572A7",
  "#AA4643",
  "#89A54E",
  "#80699B",
  "#3D96AE",
  "#DB843D",
  "#92A8CD",
  "#A47D7C",
  "#B5CA92",
  "#666666",
];

// 新ui颜色配色
export const NEW_DEFAULT_COLORS = [
  "#3963F3",
  "#8EC655",
  "#FFAD77",
  "#5EBFFF",
  "#9570E7",
  "#50A682",
  "#F4CF3B",
  "#5B97FF",
  "#FF9C11",
  "#D9D9D9",
  "#C3446D",
  "#7B7F83",
  "#6CEAC0",
  "#912A7E",
  "#BEEFED",
  "#5812A1",
  "#DDAADD",
  "#F25B2C",
  "#F9F095",
  "#BBAA22",
  "#FFE6D3",
];
// 图表水印配置
export const waterMarkOption = {
  type: "text",
  silent: true,
  left: "0",
  bottom: "5",
  z: 100,
  style: {
    fill: "#999",
    text: i18n.t("echarts.data_source") + ": " + i18n.t("echarts.company"),
    font:
      fenxiLanguage == "en"
        ? 'normal normal 14px "Lexend Deca"'
        : 'normal normal 14px "Microsoft YaHei"',
    // fontSize: 14,
  },
};

export function addWaterMark(option) {
  if (option.graphic && option.graphic.length > 0) {
  } else {
    option.graphic = [];
  }
  option.graphic.push(waterMarkOption);
}

// 引导功能配置
// export const options = {
//   nextLabel: "下一步",
//   prevLabel: "上一步",
//   skipLabel: "跳过",
//   doneLabel: "完成",
//   showBullets: false,
//   showProgress: false,
//   exitOnOverlayClick: false,
//   tooltipClass: ".introjs-tooltip",
// };
//机器人page_info需要的apiKey
export const apiKey = {
  Summary: {
    tab: i18n.t("mainHeader.tabs_summary"),
    summary: "api/sub/summary",
    trend: "api/sub/trend",
  },
  PriceDis: {
    tab: i18n.t("mainHeader.tabs_price_dis"),
    priceRange: "api/sub/price_range",
    trend: "api/sub/trend",
  },
  Catlist: {
    tab: i18n.t("mainHeader.tabs_catlist"),
    catlist: "api/sub/cat_list",
  },
  Brandlist: {
    tab: i18n.t("mainHeader.tabs_brandlist"),
    analyze: "api/sub/analyze",
    trend: "api/sub/brand_change_trend",
  },
  BrandPriceDis: {
    tab: i18n.t("mainHeader.tabs_brand_price_dis"),
    range: "api/sub/brand_price_range",
    sold: "api/sub/brand_price_sold",
  },
  Shopslist: {
    tab: i18n.t("mainHeader.tabs_shopslist"),
    analyze: "api/sub/analyze",
  },
  Itemslist: {
    tab: i18n.t("mainHeader.tabs_itemslist"),
    list: "api/sub/item_list",
  },
  Attrlist: {
    tab: i18n.t("mainHeader.tabs_attrlist"),
    attr: "api/sub/top_attr",
    analyze: "api/sub/analyze",
  },
  customAnalyze: {
    tab: i18n.t("mainHeader.tabs_custom_analyze"),
    analyze: "api/sub/rule_analyze",
  },
  PivotAnalyze: {
    tab: i18n.t("mainHeader.tabs_pivot_analyze"),
    summary: "api/sub/summary",
    trend: "api/sub/trend",
  },
  consumerInsight: {
    tab: i18n.t("mainHeader.tabs_consumer"),
    summary: "api/sub/summary",
    trend: "api/sub/trend",
  },
};
