import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "@/views/Home.vue";
import store from "@/store";
import { Message } from "element-ui";
import { getDefaultDate } from "@/api/baseInfo";
import { getUrlParams } from "@/utils";
import { parseTime } from "@/utils";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
Vue.use(VueRouter);

const originalpush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalpush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    redirect: "/search",
  },
  {
    path: "/search",
    name: "search",
    component: () =>
      import(/* webpackChunkName: "search-cpn" */ "@/views/Search.vue"),
  },
  {
    path: "/fenxi_detail/:id/:start/:end/filter",
    name: "fenxi_detail",
    component: () =>
      import(
        /* webpackChunkName: "fenxi-detail-cpn" */ "@/views/FenxiDetail.vue"
      ),
  },
  {
    path: "/detail",
    name: "detail",
    component: () =>
      import(/* webpackChunkName: "detail-cpn" */ "@/views/Detail"),
  },
  {
    path: "/subscribe",
    name: "subscribe",
    component: () =>
      import(/* webpackChunkName: "subscribe-cpn" */ "@/views/Subscribe"),
  },
  {
    path: "/myAttention",
    name: "myAttention",
    component: () =>
      import(
        /* webpackChunkName: "my-attention-cpn" */ "@/views/MyAttention.vue"
      ),
  },
  {
    path: "/tradeReport",
    name: "tradeReport",
    component: () =>
      import(
        /* webpackChunkName: "trade-report-cpn" */ "@/views/TradeReport.vue"
      ),
  },
  {
    path: "/setUp",
    name: "setUp",
    component: () =>
      import(/* webpackChunkName: "setup-cpn" */ "@/views/SetUp.vue"),
  },
  {
    path: "/skuDetail",
    name: "skuDetail",
    component: () =>
      import(/* webpackChunkName: "sku-detail-cpn" */ "@/views/SkuDetail"),
  },
  {
    path: "/filingDetail",
    name: "filingDetail",
    component: () =>
      import(
        /* webpackChunkName: "f-detail-cpn" */ "@/views/FilingDetail/FilingDetail.vue"
      ),
  },
  {
    path: "/customList",
    name: "customList",
    component: () =>
      import(/* webpackChunkName: "custom-list-cpn" */ "@/views/CustomList"),
  },
  {
    path: "/pivotList",
    name: "pivotList",
    component: () => import("@/views/PivotList"),
  },
  {
    path: "/target",
    name: "target",
    component: () => import("@/views/Target"),
  },
  {
    path: "/commentDetail",
    name: "commentDetail",
    component: () => import("@/views/CommentDetail"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/search",
  },
];

const router = new VueRouter({
  routes,
});

const getDefaultDateFun = async (to, next) => {
  let params = {};
  await getDefaultDate(params).then((res) => {
    if (res.status == "ok") {
      let GlobalParams = {
        latestTime: {},
      };
      _.forEach(res.result.plat, function (platRange, plat) {
        GlobalParams.latestTime[plat] = {
          start: platRange[0],
          end: platRange[1],
        };
        _.forEach(res.result.cid, function (cidRange, cid) {
          GlobalParams.latestTime[plat + "|" + cid] = {
            start: cidRange[0],
            end: cidRange[1],
          };
        });
      });
      var mutiPlats = ["taobao,jd", "taobao,taobao", "taobao,taobao,jd"];
      _.forEach(res.result.cid, function (cidRange, cid) {
        _.forEach(mutiPlats, function (p) {
          GlobalParams.latestTime[p + "|" + cid] = {
            start: cidRange[0],
            end: cidRange[1],
          };
        });
      });
      var taobaoTs = GlobalParams.latestTime.taobao;
      var jdTs = GlobalParams.latestTime.jd;
      var douyinTs = GlobalParams.latestTime.douyin;
      GlobalParams.latestTime["taobao,jd"] = {
        start: taobaoTs.start >= jdTs.start ? taobaoTs.start : jdTs.start,
        end: taobaoTs.end <= jdTs.end ? taobaoTs.end : jdTs.end,
      };
      GlobalParams.latestTime["jd,douyin"] = {
        start: douyinTs.start >= jdTs.start ? douyinTs.start : jdTs.start,
        end: douyinTs.end <= jdTs.end ? douyinTs.end : jdTs.end,
      };
      GlobalParams.latestTime["taobao,douyin"] = {
        start:
          taobaoTs.start >= douyinTs.start ? taobaoTs.start : douyinTs.start,
        end: taobaoTs.end <= douyinTs.end ? taobaoTs.end : douyinTs.end,
      };
      let startArr = [taobaoTs.start, jdTs.start, douyinTs.start].sort(
        (a, b) => a - b
      );
      let endArr = [taobaoTs.end, jdTs.end, douyinTs.end].sort((a, b) => a - b);
      GlobalParams.latestTime["taobao,jd,douyin"] = {
        start: startArr[startArr.length - 1],
        end: endArr[0],
      };

      let ts = GlobalParams.latestTime;
      store.dispatch("user/changeTs", ts);
      localStorage.setItem(
        "defaultTs",
        JSON.stringify(GlobalParams.latestTime)
      );
      store.dispatch(
        "user/changeStartMonth",
        store.state.user.isOversea
          ? res.result.plat.amazon_us[1]
          : res.result.plat.taobao[1]
      );
      store.dispatch(
        "user/changeEndMonth",
        store.state.user.isOversea
          ? res.result.plat.amazon_us[1]
          : res.result.plat.taobao[1]
      );
      localStorage.setItem(
        "deadLineStartMonth",
        store.state.user.isOversea
          ? res.result.plat.amazon_us[0]
          : res.result.plat.taobao[0]
      );
      localStorage.setItem(
        "deadLineEndMonth",
        store.state.user.isOversea
          ? res.result.plat.amazon_us[1]
          : res.result.plat.taobao[1]
      );
      store.dispatch(
        "user/changeDeadLineStartMonth",
        res.result.plat.taobao[0]
      );
      store.dispatch("user/changeDeadLineEndMonth", res.result.plat.taobao[1]);
      //   router.push({
      //     name: to.name,
      //     query: {
      //       start: parseTime(store.state.user.startMonth, "{y}-{m}"),
      //       end: parseTime(store.state.user.endMonth, "{y}-{m}"),
      //     },
      //   });
      next();
    }
  });
  //   } else {
  //     return;
  //   }
};

router.beforeEach(async (to, from, next) => {
  const browserFinger = localStorage.getItem("browserFinger");
  if (!browserFinger) {
    const fpPromise = FingerprintJS.load();
    const fp = await fpPromise;
    const result = await fp.get();
    localStorage.setItem("browserFinger", result.visitorId);
  }
  // 动态修改document.title
  const localeLanguage = localStorage.getItem("language");
  if (store.state.user.loginInfo.partner_title) {
    document.title = store.state.user.loginInfo.partner_title;
  } else if (document.location.origin.indexOf("mktindex.com") > 0) {
    if (localeLanguage == "en") {
      if (document.location.origin.indexOf("oversea") > 0) {
        document.title = "Moojing Oversea";
      } else {
        document.title = "Moojing Analysis+";
      }
    } else {
      if (document.location.origin.indexOf("oversea") > 0) {
        document.title = "魔镜Oversea";
      } else {
        document.title = "魔镜分析+";
      }
    }
  }
  if (to.path.includes("/fenxi_detail") || to.path.includes("/target")) {
    store.dispatch("user/changeShowMainHead", true);
    next();
    return;
  }
  if (
    to.path.includes("/search") ||
    to.path.includes("/subscribe") ||
    to.path.includes("/myAttention") ||
    to.path.includes("/setUp") ||
    to.path.includes("/skuDetail") ||
    to.path.includes("/customList") ||
    to.path.includes("/pivotList") ||
    to.path.includes("/tradeReport") ||
    to.path.includes("/filingDetail")
  ) {
    store.dispatch("user/changeShowMainHead", false);
  } else {
    store.dispatch("user/changeShowMainHead", true);
  }
  if (to.path.includes("/search")) {
    store.dispatch("user/changeShowTopHead", true);
    store.dispatch("user/changeShowFilter", false);
  } else {
    store.dispatch("user/changeShowTopHead", true);
    store.dispatch("user/changeShowFilter", false);
  }
  if (to.path.includes("/skuDetail")) {
    store.dispatch("user/changeShowItemHead", true);
  } else {
    store.dispatch("user/changeShowItemHead", false);
  }
  if (
    (!getUrlParams("start") && !getUrlParams("end")) ||
    (!store.state.user.deadLineStartMonth && !store.state.user.deadLineEndMonth)
  ) {
    await getDefaultDateFun(to, next);
  } else {
    next();
  }
  next();
});

export default router;
